/**
 * Created by Di Wang on 2018-12-10.
 */
import React from 'react';
import PropTypes from 'prop-types';
import './agenda-group.scss';

const AgendaGroup = props => (
    <div className="agenda-group">
        <div className="agenda-group__title">{props.title}</div>
        <div className="agenda-group__body">{props.children}</div>
    </div>
);

AgendaGroup.propTypes = {
    title: PropTypes.string
};

export default AgendaGroup;
