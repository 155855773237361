/**
 * Created by Di Wang on 2019-01-23.
 */
import React from 'react';
import { Button, Form, Select } from 'antd';
import PropTypes from 'prop-types';

const { Item, create } = Form;
const { Option } = Select;

@create()
class PresenterSelectionForm extends React.PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
        isRequired: PropTypes.bool,
        presenters: PropTypes.array,
        types: PropTypes.array
    };

    static defaultProps = {
        onSubmit: () => {}
    };

    render() {
        const { form, isRequired, loading, presenters, types } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this._onSubmit}>
                <Item>
                    {getFieldDecorator('role', {
                        rules: [{ required: isRequired }]
                    })(
                        <Select placeholder="Select the role">
                            {types.map(role => (
                                <Option key={role} value={role}>
                                    {role}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('user', {
                        rules: [{ required: isRequired }]
                    })(
                        <Select
                            placeholder="Select a presenter"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                            showSearch>
                            {presenters.map(presenter => (
                                <Option key={presenter.id} value={presenter.id}>
                                    {`${presenter.name} - ${presenter.company}`}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Item>
                <Button loading={loading} size="large" block type="primary" htmlType="submit">
                    SUBMIT
                </Button>
            </Form>
        );
    }

    _onSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({ ...values, role: values.role.toUpperCase() });
            }
        });
    };
}

export default PresenterSelectionForm;
