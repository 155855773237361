/**
 * Created by Di Wang on 2019-01-24.
 */
import React from 'react';
import './avatar-selector.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import UploadIndicator from '../UploadIndicator';
import { Icon } from 'antd';

export default class AvatarSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            avatar: !!props.defaultAvatar
                ? props.defaultAvatar
                : props.company
                ? require('../../asset/img/company-placeholder.svg')
                : require('../../asset/img/avatar-placeholder.png'),
            indicatorStatus: null
        };
    }

    static propTypes = {
        /** default avatar image, if null, it will display a placeholder image */
        defaultAvatar: PropTypes.string,

        /** pass the new uploaded image url */
        onChange: PropTypes.func,

        /** target folder */
        folderName: PropTypes.string,

        /** determine to display company placeholder */
        company: PropTypes.bool,

        /** determine to resize the image */
        isSquare: PropTypes.bool,

        style: PropTypes.object
    };

    static defaultProps = {
        defaultAvatar: null,
        onChange: null,
        isSquare: true
    };

    render() {
        const { avatar, indicatorStatus } = this.state;
        const cls = classnames('avatar-selector__container', {
            'avatar-selector__container_square': this.props.company,
            'avatar-selector__container_circle': !this.props.company
        });
        return (
            <div className="avatar-selector" style={this.props.style}>
                <div className={cls}>
                    <img className="avatar-selector__image" src={avatar} alt="avatar" />
                    <input
                        type="file"
                        name="file"
                        accept="image/png, image/jpeg"
                        className="avatar-selector__upload-input"
                        onChange={this._imgOnChange}
                    />
                    <div className="avatar-selector__overlay">
                        <Icon type="camera" />
                    </div>
                </div>
                <UploadIndicator
                    status={indicatorStatus}
                    className="avatar-selector__upload-indicator"
                />
            </div>
        );
    }

    _imgOnChange = async e => {
        const file = e.target.files[0];

        if (file) {
            this.setState({ indicatorStatus: UploadIndicator.STATUS.LOADING });
            // payload form data
            const data = new FormData();
            data.append('file', file);
            data.append('folder', `${this.props.folderName}`);
            //set resize for image
            if (this.props.isSquare) {
                data.append('width', '300');
                data.append('height', '300');
            }

            try {
                const res = await window.axios.post('/api/file/upload', data);
                this.setState({
                    avatar: res.data.url,
                    indicatorStatus: UploadIndicator.STATUS.DONE
                });
                const { onChange } = this.props;
                onChange && onChange(res.data.url);
            } catch (e) {
                this.setState({ indicatorStatus: UploadIndicator.STATUS.FAILED });
            }
        }
    };
}
