/**
 * Created by Di Wang on 2018-12-07.
 */
import React from 'react';
import { Button, Icon, Alert, message } from 'antd';
import './track.scss';
import TrackTable from './TrackTable';
import TrackModal from './TrackModal';
import { connect } from 'react-redux';
import { updateTracks } from '../../redux/db.redux';

@connect(
    null,
    { updateTracks }
)
class TrackPage extends React.PureComponent {
    state = {
        tracks: [],
        isFetching: false,
        isSubmitting: false,
        modalVisible: false,

        /** modal type */
        isAddMode: true,

        /** track model form data */
        trackModalDefaultValue: null
    };

    componentDidMount() {
        this._getTracks();
    }

    render() {
        const {
            tracks,
            isFetching,
            modalVisible,
            isAddMode,
            trackModalDefaultValue,
            isSubmitting
        } = this.state;
        return (
            <div className="track">
                <div className="track__toolbar">
                    <Alert
                        message="Track Tag is an universal configuration. Modifying it could affect all events."
                        type="warning"
                        showIcon
                    />
                    <Button type="primary" onClick={this._addBtnOnClick}>
                        <Icon type="plus" /> Create Track
                    </Button>
                </div>
                <TrackTable
                    loading={isFetching}
                    data={tracks}
                    deleteIconOnClick={this._tableDeleteRowOnClick}
                    editIconOnClick={this._tableEditRowOnClick}
                />
                <TrackModal
                    visible={modalVisible}
                    onCancel={() => this.setState({ modalVisible: false })}
                    isAddMode={isAddMode}
                    defaultValue={trackModalDefaultValue}
                    onSubmit={this._modalOnSubmit}
                    isSubmitting={isSubmitting}
                />
            </div>
        );
    }

    _getTracks = async () => {
        this.setState({ isFetching: true });
        const query = `
			{
			  tracks {
			    id
			    name
			    color
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            const tracks = res.data.data.tracks.map(track => ({ ...track, key: track.id }));
            this.setState({ tracks, isFetching: false });
            this.props.updateTracks(tracks);
        } catch (e) {
            this.setState({ isFetching: false });
        }
    };

    _tableDeleteRowOnClick = async track => {
        const query = `
			mutation {
			  deleteTrack(id: "${track.id}") {
			    id
			  }
			}
		`;
        try {
            await window.axios.post('/gql', { query });
            this._getTracks();
        } catch (e) {
            console.log(e);
            message.error('Delete failed');
        }
    };

    _modalOnSubmit = async values => {
        this.setState({ isSubmitting: true });
        const { isAddMode } = this.state;
        const query = `
			mutation {
				upsertTrack(id: "${values.id}", name: "${values.name}", color: "${values.color}") {
					id
				}
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.upsertTrack) {
                message.success(`${isAddMode ? 'Create' : 'Update'} successful`);
                this.setState({ modalVisible: false });
                this._getTracks();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error(`${isAddMode ? 'Create' : 'Update'} failed`);
        }

        this.setState({ isSubmitting: false });
    };

    _tableEditRowOnClick = track => {
        this.setState({
            modalVisible: true,
            trackModalDefaultValue: track,
            isAddMode: false
        });
    };

    _addBtnOnClick = () => {
        this.setState({
            modalVisible: true,
            trackModalDefaultValue: null,
            isAddMode: true
        });
    };
}

export default TrackPage;
