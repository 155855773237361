/**
 * Created by Di Wang on 2018-12-16.
 */
import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

const STATUS = {
    DONE: 'done',
    LOADING: 'loading',
    FAILED: 'failed'
};

const UploadIndicator = props => {
    switch (props.status) {
        case STATUS.DONE:
            return (
                <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                    className={props.className}
                />
            );

        case STATUS.LOADING:
            return <Icon type="sync" spin className={props.className} />;

        case STATUS.FAILED:
            return (
                <Icon
                    type="close-circle"
                    theme="twoTone"
                    twoToneColor="#f30"
                    className={props.className}
                />
            );

        default:
            return null;
    }
};

UploadIndicator.propTypes = {
    status: PropTypes.oneOf([STATUS.DONE, STATUS.LOADING, STATUS.FAILED]),
    className: PropTypes.string
};

UploadIndicator.defaultProps = {
    status: null
};

UploadIndicator.STATUS = STATUS;

export default UploadIndicator;
