/**
 * Created by Di Wang on 2018-12-07.
 */
import React, { PureComponent } from 'react';
import { Icon, Popconfirm, Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import './track-table.scss';

export default class TrackTable extends PureComponent {
    static propTypes = {
        data: PropTypes.array,
        loading: PropTypes.bool,
        editIconOnClick: PropTypes.func,
        deleteIconOnClick: PropTypes.func
    };

    static defaultProps = {
        editIconOnClick: () => {},
        deleteIconOnClick: () => {}
    };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Color',
            dataIndex: 'color',
            align: 'center',
            render: val => (
                <Tooltip title={val}>
                    <span className="track-table__color-dot" style={{ backgroundColor: val }} />
                </Tooltip>
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <span>
                    <Icon
                        type="edit"
                        theme="twoTone"
                        className="track-table__icon"
                        onClick={() => this.props.editIconOnClick(record)}
                    />
                    <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => this.props.deleteIconOnClick(record)}>
                        <Icon type="delete" theme="twoTone" className="track-table__icon" />
                    </Popconfirm>
                </span>
            )
        }
    ];

    render() {
        const { loading, data } = this.props;
        return (
            <Table
                loading={loading}
                columns={this.columns}
                dataSource={data}
                className="track-table"
            />
        );
    }
}
