/**
 * Created by Di Wang on 2019-04-25.
 */
import React from 'react';
import { Modal, Form, Input, Button, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { Item, create } = Form;
const { TextArea } = Input;

@create()
class PollModal extends React.PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        onSubmit: PropTypes.func,
        isAddMode: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        initialData: PropTypes.object
    };

    _onSubmit = e => {
        e.preventDefault();
        const { form, isAddMode, initialData } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({ ...values, id: isAddMode ? '' : initialData.id });
            }
        });
    };

    render() {
        const { isAddMode, visible, onCancel, isSubmitting, form, initialData } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                footer={null}
                title={isAddMode ? 'Add Poll' : 'Edit Poll'}
                onCancel={onCancel}
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}>
                <Form onSubmit={this._onSubmit} style={{ padding: '0 20px 10px' }}>
                    <Item>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Poll name is required.' }],
                            initialValue: 'name' in initialData ? initialData.name : ''
                        })(<Input placeholder="Poll topic" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('description', {
                            initialValue:
                                'description' in initialData ? initialData.description : ''
                        })(<TextArea placeholder="Description" rows={3} />)}
                    </Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Item>
                            {getFieldDecorator('startAt', {
                                rules: [
                                    {
                                        type: 'object',
                                        required: true,
                                        message: 'Start time is required.'
                                    }
                                ],
                                initialValue:
                                    'startAt' in initialData ? moment(initialData.startAt) : null
                            })(
                                <DatePicker
                                    format="DD/MM/YYYY HH:mm"
                                    showTime={{ format: 'HH:mm' }}
                                    placeholder="Set Start Time"
                                    disabledDate={current =>
                                        current &&
                                        current <
                                            moment()
                                                .endOf('day')
                                                .subtract(1, 'day')
                                    }
                                />
                            )}
                        </Item>
                        <Item>
                            {getFieldDecorator('endAt', {
                                rules: [{ required: true, message: 'End time is required.' }],
                                initialValue:
                                    'endAt' in initialData ? moment(initialData.endAt) : null
                            })(
                                <DatePicker
                                    format="DD/MM/YYYY HH:mm"
                                    showTime={{ format: 'HH:mm' }}
                                    placeholder="Set End Time"
                                />
                            )}
                        </Item>
                    </div>
                    <p style={{ marginBottom: 0 }}>Options</p>
                    <Item>
                        {getFieldDecorator('option1', {
                            rules: [{ required: true, message: 'Option1 is required.' }],
                            initialValue: 'options' in initialData ? initialData.options[0] : ''
                        })(<Input placeholder="Poll option1" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('option2', {
                            rules: [{ required: true, message: 'Option2 is required.' }],
                            initialValue: 'options' in initialData ? initialData.options[1] : ''
                        })(<Input placeholder="Poll option2" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('option3', {
                            initialValue:
                                'options' in initialData && initialData.options[2]
                                    ? initialData.options[2]
                                    : ''
                        })(<Input placeholder="Poll option3" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('option4', {
                            initialValue:
                                'options' in initialData && initialData.options[3]
                                    ? initialData.options[3]
                                    : ''
                        })(<Input placeholder="Poll option4" />)}
                    </Item>
                    <Button
                        loading={isSubmitting}
                        size="large"
                        block
                        htmlType="submit"
                        type="primary">
                        {isAddMode ? 'ADD' : 'UPDATE'}
                    </Button>
                </Form>
            </Modal>
        );
    }
}

export default PollModal;
