/**
 * Created by Di Wang on 2018-12-05.
 */
import React, { PureComponent } from 'react';
import './event-table.scss';
import { Table, Icon, Switch } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class EventTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    itemOnEdit: PropTypes.func,
    itemOnDelete: PropTypes.func,
    itemOnSwitch: PropTypes.func
  };

  static defaultProps = {
    itemOnDelete: () => {},
    itemOnEdit: () => {},
    itemOnSwitch: () => {}
  };

  columns = [
    {
      title: 'Event Id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      align: 'center',
      render: val => (
        <img
          src={val ? val : require('../../../asset/img/placeholder.png')}
          alt="logo"
          width={20}
        />
      )
    },
    {
      title: 'Join Code',
      dataIndex: 'joinCode',
      align: 'center'
    },
    {
      title: 'Hosted At',
      dataIndex: 'date',
      align: 'center',
      render: val =>
        val ? (
          <span>
            {moment(val)
              .utc()
              .format('DD/MM/YYYY')}
          </span>
        ) : (
          <span>Unset</span>
        )
    },
    {
      title: 'Published',
      dataIndex: 'published',
      align: 'center',
      render: (val, record) => (
        <Switch
          defaultChecked={val}
          size="small"
          onChange={checked => this.props.itemOnSwitch(record.id, checked)}
        />
      )
    },
    {
      title: 'Action',
      align: 'center',
      render: (text, record) => (
        <span>
          <Icon
            type="edit"
            theme="twoTone"
            className="event-table__icon"
            onClick={() => this.props.itemOnEdit(record)}
          />
          <Icon
            type="delete"
            theme="twoTone"
            className="event-table__icon"
            onClick={() => this.props.itemOnDelete(record.id)}
          />
        </span>
      )
    }
  ];

  render() {
    const { loading, data } = this.props;
    return (
      <Table loading={loading} columns={this.columns} dataSource={data} className="event-table" />
    );
  }
}
