/**
 * Created by Di Wang on 2019-03-01.
 */
import React from 'react';
import './notification.scss';
// import { Tabs } from 'antd';
// import IndividualNotification from './IndividualNotification';
import NotificationTutorial from './NotificationTutorial';

// const { TabPane } = Tabs;

const NotificationPage = () => {
    return (
        <div className="notification">
            <NotificationTutorial />
            {/*<Tabs defaultActiveKey="1">*/}
            {/*    <TabPane tab="Group" key="1">*/}
            {/*        <NotificationTutorial />*/}
            {/*    </TabPane>*/}
            {/*    <TabPane tab="Individual" key="2">*/}
            {/*        <IndividualNotification />*/}
            {/*    </TabPane>*/}
            {/*</Tabs>*/}
        </div>
    );
};

export default NotificationPage;
