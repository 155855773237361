/**
 * Created by Di Wang on 2018-12-05.
 */
import React, { PureComponent } from 'react';
import './event.scss';
import { Button, Input, Icon, message } from 'antd';
import EventTable from './EventTable';
import CreateEventModal from './CreateEventModal';
import { browserHistory } from 'react-router';
import DeleteEventModal from './DeleteEventModal';
import moment from 'moment';

const { Search } = Input;

export default class EventPage extends PureComponent {
  state = {
    events: [],
    isFetching: false,
    isSubmitting: false,
    createModalVisible: false,
    deleteModalVisible: false
  };

  async componentDidMount() {
    await this._getEvents();
  }

  render() {
    const { events, createModalVisible, deleteModalVisible, isFetching, isSubmitting } = this.state;
    return (
      <div className="event">
        <div className="toolbar">
          <Search
            placeholder="Search event"
            onSearch={value => console.log(value)}
            className="event__search-bar"
            enterButton
          />
          <Button type="primary" onClick={() => this.setState({ createModalVisible: true })}>
            <Icon type="plus" /> Create Event
          </Button>
        </div>
        <EventTable
          loading={isFetching}
          data={events}
          itemOnDelete={this._tableDeleteRow}
          itemOnEdit={this._tableEditRow}
          itemOnSwitch={this._tableSwitchOnChange}
        />
        <CreateEventModal
          isSubmitting={isSubmitting}
          onSubmit={this._createEventModalOnSubmit}
          visible={createModalVisible}
          onCancel={() => this.setState({ createModalVisible: false })}
        />
        <DeleteEventModal
          visible={deleteModalVisible}
          isSubmitting={isSubmitting}
          onSubmit={this._deleteModalOnSubmit}
          onCancel={() => this.setState({ deleteModalVisible: false })}
        />
      </div>
    );
  }

  /**
   * Callback when the modal form submits
   * @param values
   * @returns {Promise<void>}
   * @private
   */
  _createEventModalOnSubmit = async values => {
    this.setState({ isSubmitting: true });
    const { name, date } = values;
    const UTCDate = moment.utc([date.year(), date.month(), date.date()]);
    const query = `
			mutation {
				createEvent(name: "${name}", date: "${UTCDate.toISOString()}") {
					id
				}
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.createEvent) {
        message.success('Create successfully');
        this.setState({ isSubmitting: false, createModalVisible: false });
        this._getEvents();
      } else {
        message.error(res.data.errors[0].message);
        this.setState({ isSubmitting: false });
      }
    } catch (e) {
      console.log(e);
      message.error('Create unsuccessfully');
      this.setState({ isSubmitting: false });
    }
  };

  /**
   * Get event data
   * @returns {Promise<void>}
   * @private
   */
  _getEvents = async () => {
    this.setState({ isFetching: true });
    const query = `
			{
			  events {
			    id
			    name
			    logo
			    joinCode
			    date
			    published
			    folderName
			  }
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.errors) {
        if (res.data.errors[0].message === 'jwt expired') message.error('Session expired');
        browserHistory.replace('/no-auth');
      } else {
        const events = res.data.data.events.map(event => ({ ...event, key: event.id }));
        this.setState({ events });
        this.setState({ isFetching: false });
      }
    } catch (e) {
      console.log(e);
      message.error('Fetch event list failed');
      this.setState({ isFetching: false });
    }
  };

  /**
   * Update published field
   * @param id
   * @param checked
   * @returns {Promise<void>}
   * @private
   */
  _tableSwitchOnChange = async (eventId, checked) => {
    const query = `
            mutation {
              updateEvent(id: "${eventId}", published: ${checked}) {
                id
              }
            }
        `;

    try {
      await window.axios.post('/gql', { query });
    } catch (e) {
      console.log(e);
      message.error('Update failed');
    }
  };

  /**
   * Table item onDelete callback
   * @param id
   * @returns {Promise<void>}
   * @private
   */
  _tableDeleteRow = async eventId => {
    this.eventId = eventId;
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible });
  };

  /**
   * Delete modal onSubmit callback
   * delete the event
   * @param values
   * @returns {Promise<void>}
   * @private
   */
  _deleteModalOnSubmit = async values => {
    this.setState({ isSubmitting: true });
    const query = `
			mutation {
			  deleteEvent(id: "${this.eventId}", password: "${values.password}") {
			    id
			  }
			}
		`;
    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deleteEvent) {
        const events = [...this.state.events];
        const idx = events.findIndex(event => event.id === this.eventId);
        if (idx !== -1) {
          events.splice(idx, 1);
          this.setState({ events, deleteModalVisible: false });
        }
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      console.log(e);
      message.error('Delete failed');
    }
    this.setState({ isSubmitting: false });
  };

  /**
   * When table row click, pass the event id to the detail page
   * @param record
   * @private
   */
  _tableEditRow = record => {
    browserHistory.push({
      pathname: '/event/detail',
      state: {
        event: {
          id: record.id,
          name: record.name,
          folderName: record.folderName,
          date: record.date
        }
      }
    });
  };
}
