/**
 * Created by Di Wang on 2018-12-22.
 */
import { combineReducers } from 'redux';
import db from './redux/db.redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

const dbPersistConfig = {
    key: 'db',
    storage: storage,
    blacklist: ['staticUsers']
};

export default combineReducers({
    db: persistReducer(dbPersistConfig, db)
});
