/**
 * Created by Di Wang on 2018-12-08.
 */
import React, { PureComponent } from 'react';
import { Button, Icon, message } from 'antd';
import AgendaGroup from './AgendaGroup';
import SessionItem from './SessionItem';
import SessionModal from './SessionModal';
import moment from 'moment';
import Loader from '../../../component/Loader';
import { connect } from 'react-redux';
import { updateTracks, updateStaticUsers } from '../../../redux/db.redux';
import FileModal from './FileModal';
import PresenterModal from './PresenterModal';
import AudioModal from './AudioModal';

const { Group } = Button;

@connect(
  state => state,
  { updateTracks, updateStaticUsers }
)
class EventAgenda extends PureComponent {
  /** for file and presenter updating purpose, connecting the session */
  sessionId = null;
  state = {
    sessions: {},

    /** modals visibility */
    sessionModalVisible: false,
    fileModalVisible: false,
    presenterModalVisible: false,
    audioModalVisible: false,

    /** modals' form default value */
    sessionModalDefaultValue: null,
    fileModalDefaultValue: null,
    audioModalDefaultValue: null,

    /** form mode */
    isAddMode: true,

    isSubmitting: false,
    isFetching: false
  };

  _fileAddBtnOnClick = sessionId => {
    this.sessionId = sessionId;
    this.setState({
      fileModalVisible: true,
      isAddMode: true,
      fileModalDefaultValue: null
    });
  };

  _fileEditBtnOnClick = (sessionId, file) => {
    this.sessionId = sessionId;
    this.setState({
      fileModalVisible: true,
      isAddMode: false,
      fileModalDefaultValue: file
    });
  };

  _fileDeleteBtnOnClick = async file => {
    const query = `
			mutation {
			  deleteSlide(id: "${file.id}") {
			    id
			  }
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deleteSlide) {
        message.success('Delete slide successfully');
        this._getSessions();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Delete slide unsuccessfully');
    }
  };

  _fileOnSubmit = async values => {
    this.setState({ isSubmitting: true });
    const { id, key, url, name } = values;
    const { isAddMode } = this.state;

    let query = '';
    if (isAddMode) {
      query = `
				mutation {
				  createSlide(
				    sessionId: "${this.sessionId}"
				    name: "${name}"
				    url: "${url}"
				    key: "${key}"
				  ) {
				    id
				  }
				}
			`;
    } else {
      query = `
				mutation {
				  updateSlide(
				    id: "${id}"
				    name: "${name}"
				    url: "${url}"
				    key: "${key}"
				  ) {
				    id
				  }
				}
			`;
    }

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.errors) {
        message.error(res.data.errors[0].message);
      } else {
        message.success(`${isAddMode ? 'Create' : 'Update'} slide file successful`);
        this._submitSucceeded('fileModalVisible');
      }
    } catch (e) {
      message.error(`${isAddMode ? 'Create' : 'Update'} slide file failed`);
    }

    this.setState({ isSubmitting: false });
  };

  _audioAddBtnOnClick = sessionId => {
    this.sessionId = sessionId;
    this.setState({
      audioModalVisible: true,
      isAddMode: true,
      audioModalDefaultValue: null
    });
  };

  _audioEditBtnOnClick = (sessionId, audio) => {
    this.sessionId = sessionId;
    this.setState({
      audioModalVisible: true,
      isAddMode: false,
      audioModalDefaultValue: audio
    });
  };

  _audioDeleteBtnOnClick = async audio => {
    const query = `
			mutation {
			  deleteAudio(id: "${audio.id}") {
			    id
			  }
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deleteAudio) {
        message.success('Delete audio successfully');
        this._getSessions();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Delete audio unsuccessfully');
    }
  };

  _audioOnSubmit = async values => {
    this.setState({ isSubmitting: true });
    const { id, url, name } = values;
    const { isAddMode } = this.state;

    let query = '';
    if (isAddMode) {
      query = `
				mutation {
				  createAudio(
				    sessionId: "${this.sessionId}"
				    name: "${name}"
				    url: "${url}"
				  ) {
				    id
				  }
				}
			`;
    } else {
      query = `
				mutation {
				  updateAudio(
				    id: "${id}"
				    name: "${name}"
				    url: "${url}"
				  ) {
				    id
				  }
				}
			`;
    }

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.errors) {
        message.error(res.data.errors[0].message);
      } else {
        message.success(`${isAddMode ? 'Create' : 'Update'} audio link successfully`);
        this._submitSucceeded('audioModalVisible');
      }
    } catch (e) {
      message.error(`${isAddMode ? 'Create' : 'Update'} audio link unsuccessfully`);
    }

    this.setState({ isSubmitting: false });
  };

  _presenterAddBtnOnClick = sessionId => {
    this.sessionId = sessionId;
    this.setState({
      presenterModalVisible: true,
      isAddMode: true
    });
  };

  _presenterDeleteBtnOnClick = async presenterId => {
    let query = `
			mutation {
				deletePresenter(id: "${presenterId}") {
					id
				}
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deletePresenter) {
        message.success('Remove successful');
        this._submitSucceeded('presenterModalVisible');
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Remove presenter failed');
    }
  };

  _presenterOnSubmit = async (isSelectAction, values) => {
    this.setState({ isSubmitting: true });
    let query = '';
    if (isSelectAction) {
      query = `
				mutation {
				  createPresenterByExist(
				    role: ${values.role}
				    staticUserId: "${values.user}", 
				    sessionId: "${this.sessionId}"
				  ) {
				    id
				  }
				}
			`;
      try {
        const res = await window.axios.post('/gql', { query });
        if (res.data.data.createPresenterByExist) {
          message.success('Add presenter successful');
          this._submitSucceeded('presenterModalVisible');
        } else {
          message.error(res.data.errors[0].message);
        }
      } catch (e) {
        message.error('Add presenter failed');
      }
    } else {
      query = `
				mutation(
				  $avatar: String
				  $title: String
				  $company: String
				  $bio: String
				  $linkedin: String
				  $twitter: String
				  $instagram: String
				  $facebook: String
				) {
				  createPresenterByNew(
				    role: ${values.role}
				    name: "${values.name}"
				    avatar: $avatar
				    title: $title
				    company: $company
				    bio: $bio
				    linkedin: $linkedin
				    twitter: $twitter
				    instagram: $instagram
				    facebook: $facebook
				    sessionId: "${this.sessionId}"
				  ) {
				    id
				  }
				}
			`;

      const { avatar, title, company, bio, linkedin, twitter, instagram, facebook } = values;
      try {
        const res = await window.axios.post('/gql', {
          query,
          variables: {
            avatar,
            title,
            company,
            bio,
            linkedin,
            twitter,
            instagram,
            facebook
          }
        });
        if (res.data.data.createPresenterByNew) {
          message.success('Add presenter successfully');
          this._submitSucceeded('presenterModalVisible');
        } else {
          message.error(res.data.errors[0].message);
        }
      } catch (e) {
        message.error('Add presenter unsuccessfully');
      }
    }

    this.setState({ isSubmitting: false });
  };

  _sessionItemOnAdd = () => {
    this.setState({
      isAddMode: true,
      sessionModalVisible: true,
      sessionModalDefaultValue: null
    });
  };

  _sessionItemOnEdit = item => {
    const sessionModalDefaultValue = {
      id: item.id,
      name: item.name,
      startTime: item.startTime,
      endTime: item.endTime,
      trackId: item.track ? item.track.id : null,
      summary: item.summary
    };
    this.setState({
      isAddMode: false,
      sessionModalVisible: true,
      sessionModalDefaultValue
    });
  };

  _sessionItemOnDelete = async item => {
    const query = `
			mutation {
			  deleteSession(id: "${item.id}") {
			    id
			  }
			}
		`;
    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deleteSession) {
        message.success('Delete session successful');
        this._getSessions();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Delete session failed');
    }
  };

  _sessionItemOnSubmit = async values => {
    this.setState({ isSubmitting: true });
    // StartTime and EndTime are already parsed to UTC time in the modal
    const { id, name, summary, startTime, endTime, trackId } = values;
    const { isAddMode } = this.state;
    const query = `
			mutation($trackId: ID, $summary: String) {
			  upsertSession(
			    id: "${id}"
			    name: "${name}"
			    summary: $summary
			    eventId: "${this.props.location.state.event.id}"
			    startTime: "${startTime.toISOString()}"
			    endTime: "${endTime.toISOString()}"
			    trackId: $trackId
			  ) {
			    id
			  }
			}
		`;
    try {
      const res = await window.axios.post('/gql', {
        query,
        variables: {
          trackId,
          summary
        }
      });
      if (res.data.data.upsertSession) {
        message.success(`${isAddMode ? 'Create' : 'Update'} session successful`);
        this._submitSucceeded('sessionModalVisible');
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      console.log(e);
      message.error(`${isAddMode ? 'Create' : 'Update'} session failed`);
    }
    this.setState({ isSubmitting: false });
  };

  /**
   * Get a certain event's all sessions
   * @returns {Promise<void>}
   * @private
   */
  _getSessions = async () => {
    this.setState({ isFetching: true });
    const query = `
			{
			  event(id: "${this.props.location.state.event.id}") {
			    sessions {
			      id
			      name
			      startTime
			      endTime
			      summary
			      track {
			        id
			        name
			        color
			      }
			      slide {
			        id
			        name
			        url
			        key
			      }
			      audio {
			        id
			        name
			        url
			      }
			      presenters {
			        id
			        role
			        user {
			          id
			          name
			          company
			        }
			      }
			    }
			  }
			}
		`;
    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.event) {
        let map = new Map();
        res.data.data.event.sessions.forEach(session => {
          const key = moment(session.startTime)
            .utc()
            .format('hh:00 A');
          const list = map.get(key) || [];
          list.push(session);
          map.set(key, list);
        });

        const sessions = {};
        map.forEach((value, key) => {
          sessions[key] = value;
        });
        this.setState({
          sessions,
          isFetching: false
        });
      } else {
        message.error(res.data.data.errors[0].message);
        this.setState({ isFetching: false });
      }
    } catch (e) {
      message.error('Get session data unsuccessfully');
      this.setState({ isFetching: false });
    }
  };

  /**
   * Get track list if store doesn't have
   * @returns {Promise<void>}
   * @private
   */
  _getTracks = async () => {
    if (this.props.db.tracks.length <= 0) {
      const query = `
				{
				  tracks {
				    id
				    name
				    color
				  }
				}
	    `;

      try {
        const res = await window.axios.post('/gql', { query });
        const { tracks } = res.data.data;
        if (tracks) {
          this.props.updateTracks(tracks);
        } else {
          message.error(res.data.errors[0].message);
        }
      } catch (e) {
        console.log(e);
        message.error('Get track list failed');
      }
    }
  };

  /**
   * Get latest sessions and close modal
   * @param modal
   * @private
   */
  _submitSucceeded = modal => {
    this._getSessions();
    this.setState({ [modal]: false });
  };

  /**
   * Get user list data
   * @returns {Promise<void>}
   * @private
   */
  _getStaticUsers = async () => {
    const query = `
			{
			  staticUsers {
			    id
			    name
			    company
			  }
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      const { staticUsers } = res.data.data;
      if (staticUsers) {
        this.props.updateStaticUsers(staticUsers);
      }
    } catch (e) {
      message.error('Get user list failed');
    }
  };

  componentDidMount() {
    this._getSessions();
    this._getTracks();
    this._getStaticUsers();
  }

  render() {
    const {
      sessions,
      sessionModalVisible,
      fileModalVisible,
      audioModalVisible,
      presenterModalVisible,
      sessionModalDefaultValue,
      fileModalDefaultValue,
      audioModalDefaultValue,
      isAddMode,
      isSubmitting,
      isFetching
    } = this.state;
    return (
      <div className="event-agenda">
        {isFetching && <Loader topRightCorner={true} size={20} />}
        <div className="toolbar">
          <Group>
            {/*<Button>All</Button>*/}
            {/*<Button>Track 1</Button>*/}
            {/*<Button>Track 2</Button>*/}
            {/*<Button>Track 3</Button>*/}
          </Group>
          <Button type="primary" onClick={this._sessionItemOnAdd}>
            <Icon type="plus" /> Add Session
          </Button>
        </div>
        {Object.keys(sessions).map(key => (
          <AgendaGroup key={key} title={key}>
            {sessions[key].map(session => (
              <SessionItem
                key={session.id}
                item={session}
                editOnClick={this._sessionItemOnEdit}
                deleteOnClick={this._sessionItemOnDelete}
                fileAddBtnOnClick={this._fileAddBtnOnClick}
                fileEditBtnOnClick={this._fileEditBtnOnClick}
                fileDeleteBtnOnClick={this._fileDeleteBtnOnClick}
                audioAddBtnOnClick={this._audioAddBtnOnClick}
                audioEditBtnOnClick={this._audioEditBtnOnClick}
                audioDeleteBtnOnClick={this._audioDeleteBtnOnClick}
                presenterAddBtnOnClick={this._presenterAddBtnOnClick}
                presenterDeleteBtnOnClick={this._presenterDeleteBtnOnClick}
              />
            ))}
          </AgendaGroup>
        ))}
        <SessionModal
          isAddMode={isAddMode}
          onCancel={() => this.setState({ sessionModalVisible: false })}
          visible={sessionModalVisible}
          defaultValue={sessionModalDefaultValue}
          tracks={this.props.db.tracks}
          isSubmitting={isSubmitting}
          onSubmit={this._sessionItemOnSubmit}
          defaultEventDate={this.props.location.state.event.date}
        />

        <FileModal
          isAddMode={isAddMode}
          visible={fileModalVisible}
          onCancel={() => this.setState({ fileModalVisible: false })}
          defaultValue={fileModalDefaultValue}
          isSubmitting={isSubmitting}
          onSubmit={this._fileOnSubmit}
          eventFolderName={this.props.location.state.event.folderName}
        />

        <PresenterModal
          isAddMode={isAddMode}
          visible={presenterModalVisible}
          onCancel={() => this.setState({ presenterModalVisible: false })}
          isSubmitting={isSubmitting}
          onSubmit={this._presenterOnSubmit}
          staticUsers={this.props.db.staticUsers}
          eventFolderName={this.props.location.state.event.folderName}
        />

        <AudioModal
          isAddMode={isAddMode}
          visible={audioModalVisible}
          onCancel={() => this.setState({ audioModalVisible: false })}
          defaultValue={audioModalDefaultValue}
          isSubmitting={isSubmitting}
          onSubmit={this._audioOnSubmit}
        />
      </div>
    );
  }
}

export default EventAgenda;
