/**
 * Created by Di Wang on 2018-12-06.
 */
import React, { PureComponent } from 'react';
import { Menu } from 'antd';
import PropsTypes from 'prop-types';
import './side-menu.scss';
import { browserHistory } from 'react-router';

const { SubMenu, Item } = Menu;

export default class SideMenu extends PureComponent {
    static propTypes = {
        menus: PropsTypes.array,

        /* router prefix */
        prefix: PropsTypes.string,

        location: PropsTypes.object
    };

    static defaultProps = {
        menus: [],
        prefix: '/'
    };

    render() {
        return (
            <Menu
                className="side-menu"
                mode="inline"
                defaultSelectedKeys={[
                    `${window.location.pathname.replace(this.props.prefix + '/', '')}`
                ]}>
                {this.props.menus.map(menu =>
                    menu.children ? (
                        <SubMenu key={menu.name} title={menu.name}>
                            {menu.children.map(child => (
                                <Item
                                    key={child.route}
                                    onClick={this._menuOnClick.bind(this, child.route)}>
                                    {child.name}
                                </Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Item key={menu.route} onClick={this._menuOnClick.bind(this, menu.route)}>
                            {menu.name}
                        </Item>
                    )
                )}
            </Menu>
        );
    }

    _menuOnClick = route => {
        browserHistory.push({
            pathname: `${this.props.prefix}/${route}`,
            state: this.props.location.state
        });
    };
}
