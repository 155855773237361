/**
 * Created by Di Wang on 2019-04-25.
 */
import React from 'react';
import { Icon, Table, Popconfirm } from 'antd/lib/index';

const ExhibitorTable = ({ loading, data, onDelete, onEdit }) => {
    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company.name'
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <span>
                    <Icon
                        type="edit"
                        theme="twoTone"
                        className="event-table__icon"
                        onClick={() => onEdit(record)}
                    />
                    <Popconfirm title="Are you sure?" onConfirm={() => onDelete(record)}>
                        <Icon type="delete" theme="twoTone" className="event-table__icon" />
                    </Popconfirm>
                </span>
            )
        }
    ];

    return <Table loading={loading} dataSource={data} columns={columns} rowKey={item => item.id} />;
};

export default ExhibitorTable;
