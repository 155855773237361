/**
 * @author Di Wang
 */
import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import PropTypes from 'prop-types';
import './pie-chart.scss';

export default class PieChart extends React.PureComponent {
  static props = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    }),
    loading: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    loading: true
  };

  state = {
    loading: true
  };

  componentDidMount() {
    this.container = echarts.init(document.getElementById(this.props.id), 'wonderland');
    this.container.showLoading();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, title } = this.props;
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        right: 20,
        data: data.map(item => item.name)
      },
      series: [
        {
          name: title,
          type: 'pie',
          radius: '40%',
          center: ['35%', '50%'],
          data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.container.hideLoading();
    this.container.setOption(option);
  }

  render() {
    return (
      <div className="pie-chart__container">
        <h4 className="pie-chart__title">{this.props.title}</h4>
        <div id={this.props.id} style={{ width: 400, height: 300 }} />
      </div>
    );
  }
}
