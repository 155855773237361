/**
 * Created by Di Wang on 2018-12-05.
 */
import React from 'react';
import { Modal, Input, Button, Form, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { create } = Form;
const FormItem = Form.Item;

@create()
class CreateEventModal extends React.PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
  };

  static defaultProps = {
    onSubmit: () => {}
  };

  render() {
    const { visible, onCancel, isSubmitting } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="Create Event"
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancel}
        visible={visible}>
        <Form onSubmit={this._submitForm} style={{ padding: '10px 50px' }}>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Event name is required.' }]
            })(<Input placeholder="Event Name" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('date', {
              rules: [
                {
                  required: true,
                  type: 'object',
                  message: 'Event date is required.'
                }
              ]
            })(
              <DatePicker
                placeholder="Event Date"
                disabledDate={current => current && current < moment().endOf('day')}
                style={{ width: '100%' }}
              />
            )}
          </FormItem>
          <Button htmlType="submit" type="primary" block loading={isSubmitting} size="large">
            Submit
          </Button>
        </Form>
      </Modal>
    );
  }

  _submitForm = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };
}

export default CreateEventModal;
