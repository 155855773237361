/**
 * Created by Di Wang on 2018-12-08.
 */
import React, { PureComponent } from 'react';
import Editor from '../../../component/Editor';
import RequiredTitle from '../../../component/RequiredTitle';
import { Input, Button, message } from 'antd';
import Loader from '../../../component/Loader';

export default class EventResource extends PureComponent {
    state = {
        isFetching: true,
        isSubmitting: false,
        wifiContent: null,
        floorPlanContent: null,
        travelInfoContent: null,
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: ''
    };

    async componentDidMount() {
        await this._initData();
    }

    render() {
        const { isSubmitting, isFetching } = this.state;
        if (isFetching) {
            return <Loader block />;
        } else {
            return (
                <>
                    <RequiredTitle>Social Media</RequiredTitle>
                    <Input
                        defaultValue={this.defaultValue.facebook}
                        placeholder="Facebook home page"
                        onChange={e => {
                            this.setState({ facebook: e.target.value });
                        }}
                        className="event-basic__input"
                    />
                    <Input
                        defaultValue={this.defaultValue.twitter}
                        placeholder="Twitter home page"
                        onChange={e => {
                            this.setState({ twitter: e.target.value });
                        }}
                        className="event-basic__input"
                    />
                    <Input
                        defaultValue={this.defaultValue.linkedin}
                        placeholder="Linkedin home page"
                        onChange={e => {
                            this.setState({ linkedin: e.target.value });
                        }}
                        className="event-basic__input"
                    />
                    <Input
                        defaultValue={this.defaultValue.instagram}
                        placeholder="Instagram home page"
                        onChange={e => {
                            this.setState({ instagram: e.target.value });
                        }}
                        className="event-basic__input"
                        style={{ marginBottom: '25px' }}
                    />

                    <RequiredTitle>WiFi</RequiredTitle>
                    <Editor
                        folderName={this.props.location.state.event.folderName}
                        style={{ margin: '10px 0 25px' }}
                        defaultContent={this.defaultValue.wifi}
                        editorOnChange={val => {
                            this.setState({ wifiContent: val });
                        }}
                    />

                    <RequiredTitle>Floor Plan</RequiredTitle>
                    <Editor
                        folderName={this.props.location.state.event.folderName}
                        style={{ margin: '10px 0 25px' }}
                        defaultContent={this.defaultValue.floorPlan}
                        editorOnChange={val => {
                            this.setState({ floorPlanContent: val });
                        }}
                    />

                    <RequiredTitle>Travel Information</RequiredTitle>
                    <Editor
                        folderName={this.props.location.state.event.folderName}
                        style={{ margin: '10px 0 25px' }}
                        defaultContent={this.defaultValue.travelInfo}
                        editorOnChange={val => {
                            this.setState({ travelInfoContent: val });
                        }}
                    />

                    <Button
                        htmlType="submit"
                        loading={isSubmitting}
                        type="primary"
                        size="large"
                        onClick={this._btnOnsubmit}
                        className="event-basic__save-btn">
                        Save
                    </Button>
                </>
            );
        }
    }

    /**
     * Initialise data from the server
     * @returns {Promise<void>}
     * @private
     */
    _initData = async () => {
        const query = `
			{
			  event(id: "${this.props.location.state.event.id}") {
			    linkedin
			    facebook
			    twitter
			    instagram
			    wifi
			    floorPlan
			    travelInfo
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            const {
                linkedin,
                facebook,
                twitter,
                instagram,
                wifi,
                floorPlan,
                travelInfo
            } = res.data.data.event;
            this.defaultValue = {
                linkedin,
                facebook,
                twitter,
                instagram,
                wifi: JSON.parse(wifi),
                floorPlan: JSON.parse(floorPlan),
                travelInfo: JSON.parse(travelInfo)
            };
            this.setState({ isFetching: false });
        } catch (e) {
            console.log(e);
        }
    };

    _btnOnsubmit = async () => {
        const {
            facebook,
            twitter,
            instagram,
            linkedin,
            wifiContent,
            travelInfoContent,
            floorPlanContent
        } = this.state;
        this.setState({ isSubmitting: true });

        const query = `
			mutation($wifi: String, $floorPlan: String, $travelInfo: String) {
			  updateEvent(
			    id: "${this.props.location.state.event.id}"
			    facebook: "${facebook}"
			    twitter: "${twitter}"
			    instagram: "${instagram}"
			    linkedin: "${linkedin}"
			    wifi: $wifi
			    floorPlan: $floorPlan
			    travelInfo: $travelInfo
			  ) {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', {
                query,
                variables: {
                    wifi: wifiContent.toRAW(),
                    floorPlan: floorPlanContent.toRAW(),
                    travelInfo: travelInfoContent.toRAW()
                }
            });
            if (res.data.errors) {
                console.log(res.data.errors);
                message.error('Update failed. Please check your input');
            } else {
                message.success('Update successful');
            }
        } catch (e) {
            console.log(e);
            message.error('Update failed');
        }

        this.setState({ isSubmitting: false });
    };
}
