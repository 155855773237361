/**
 * Created by Di Wang on 2018-12-17.
 */
import React from 'react';
import { browserHistory } from 'react-router';
import BraftEditor from 'braft-editor';
import './pure-html.scss';
import Loader from '../../component/Loader';

/**
 * This page is only for render pure html content of event's travelInfo, wifi, floorPlan field
 * by using query to pass the value
 *
 * eg. html?id=cjpqgygv0j4of0a42w3939pk2&field=travelInfo
 */
export default class PureHtml extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            html: '',
            isProcessing: true
        };

        const { id, field } = this.props.location.query;
        if (id && field) {
            if (field === 'wifi' || field === 'travelInfo' || field === 'floorPlan') {
                this.allowVisit = true;
                this._fetchHtml(id, field);
            } else {
                this.allowVisit = false;
                browserHistory.push('/404');
            }
        } else {
            this.allowVisit = false;
            browserHistory.push('/404');
        }
    }

    render() {
        if (this.allowVisit) {
            if (this.state.isProcessing) {
                return (
                    <div style={{ marginTop: '20px' }}>
                        <Loader />
                    </div>
                );
            } else {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: this.state.html }}
                        className="pure-html"
                    />
                );
            }
        } else {
            return null;
        }
    }

    _fetchHtml = async (id, field) => {
        const query = `
			{
			  event(id: "${id}") {
			    ${field}
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            const html = BraftEditor.createEditorState(res.data.data.event[`${field}`]).toHTML();
            this.setState({
                html,
                isProcessing: false
            });
        } catch (e) {}
    };
}
