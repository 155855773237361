/**
 * Created by Di Wang on 2019-01-03.
 */
import React from 'react';
import { Modal, Radio } from 'antd';
import PropTypes from 'prop-types';
import PresenterSelectionForm from './PresenterSelectionForm';
import PresenterCreationForm from './PresenterCreationForm';

const { Group } = Radio;
const PRESENTER_TYPES = ['Speaker', 'Moderator', 'Panelist'];

export default class PresenterModal extends React.PureComponent {
    static propTypes = {
        eventFolderName: PropTypes.string,
        staticUsers: PropTypes.array,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
        isAddMode: PropTypes.bool
    };

    static defaultProps = {
        onCancel: () => {},
        onSubmit: () => {}
    };

    state = {
        /** whether selecting a presenter or filling a form to create */
        isSelectAction: true
    };

    render() {
        const { visible, onCancel, staticUsers, isSubmitting } = this.props;
        const { isSelectAction } = this.state;
        return (
            <Modal
                footer={null}
                maskClosable={false}
                title={`Add Presenter Info`}
                onCancel={onCancel}
                destroyOnClose={true}
                visible={visible}>
                <div style={{ padding: '0 20px 20px' }}>
                    <Group
                        style={{ marginBottom: '20px' }}
                        value={isSelectAction}
                        onChange={e =>
                            this.setState({
                                isSelectAction: e.target.value
                            })
                        }>
                        <Radio value={true}>Select a presenter</Radio>
                        <Radio value={false}>Create a presenter</Radio>
                    </Group>
                    {isSelectAction ? (
                        <PresenterSelectionForm
                            isRequired={isSelectAction}
                            presenters={staticUsers}
                            loading={isSubmitting}
                            onSubmit={this._formOnSubmit}
                            types={PRESENTER_TYPES}
                        />
                    ) : (
                        <PresenterCreationForm
                            isRequired={!isSelectAction}
                            loading={isSubmitting}
                            onSubmit={this._formOnSubmit}
                            types={PRESENTER_TYPES}
                        />
                    )}
                </div>
            </Modal>
        );
    }

    _formOnSubmit = async values => {
        this.props.onSubmit(this.state.isSelectAction, values);
    };
}
