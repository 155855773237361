/**
 * Created by Di Wang on 2019-04-26.
 */
import React from 'react';
import { Button, Icon, message } from 'antd';
import ExhibitorTable from './ExhibitorTable';
import ExhibitorModal from './ExhibitorModal';

class Exhibitor extends React.PureComponent {
  state = {
    modalVisible: false,
    isAddMode: true,
    exhibitors: [],
    /** list for select exhibitors to add */
    allCompanies: [],
    isFetching: true,
    isSubmitting: false,
    initialData: {}
  };

  _onSubmit = async (values, isByExist) => {
    if (this.state.isAddMode) {
      if (isByExist) {
        this._addExhibitorByExisting(values);
      } else {
        this._addExhibitorByNew(values);
      }
    } else {
      this._updateCompany(values);
    }
  };

  _getAllCompanies = async () => {
    const query = `
		  {
			  companies {
			    id
			    name
			  }
			}
    `;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.companies) {
        this.setState({ allCompanies: res.data.data.companies });
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Get company list unsuccessfully');
    }
  };

  _getExhibitors = async () => {
    this.setState({ isFetching: true });
    const query = `
			{
			  event(id: "${this.props.location.state.event.id}") {
			    exhibitors {
			      id
			      company {
			        id
			        name
			        logo
			        description
			        website
			        facebook
			        twitter
			        instagram
			        linkedin
			      }
			    }
			  }
			}
    `;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.event) {
        this.setState({ exhibitors: res.data.data.event.exhibitors });
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Get exhibitor list unsuccessfully');
    }

    this.setState({ isFetching: false });
  };

  _updateCompany = async values => {
    this.setState({ isSubmitting: true });
    const query = `
			mutation(
			  $logo: String
			  $website: String
			  $description: String
			  $facebook: String
			  $twitter: String
			  $instagram: String
			  $linkedin: String
			) {
			  updateCompany(
			    id: "${values.id}"
			    name: "${values.name}"
			    logo: $logo
			    website: $website
			    description: $description
			    facebook: $facebook
			    twitter: $twitter
			    instagram: $instagram
			    linkedin: $linkedin
			  ) {
			    id
			  }
			}
		`;

    const { logo, website, description, facebook, twitter, instagram, linkedin } = values;
    try {
      const res = await window.axios.post('/gql', {
        query,
        variables: { logo, website, description, facebook, twitter, instagram, linkedin }
      });
      if (res.data.data.updateCompany) {
        message.success('Update company info successfully');
        this.setState({ modalVisible: false });
        this._getExhibitors();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Update company info unsuccessfully');
    }
    this.setState({ isSubmitting: false });
  };

  _addExhibitorByNew = async values => {
    this.setState({ isSubmitting: true });
    const query = `
	    mutation(
			  $logo: String
			  $website: String
			  $description: String
			  $facebook: String
			  $twitter: String
			  $instagram: String
			  $linkedin: String
			) {
			  createExhibitorByNew(
			    eventId: "${this.props.location.state.event.id}"
			    name: "${values.name}"
			    logo: $logo
			    website: $website
			    description: $description
			    facebook: $facebook
			    twitter: $twitter
			    instagram: $instagram
			    linkedin: $linkedin
			  ) {
			    id
			  }
			}
		`;

    const { logo, description, website, facebook, twitter, instagram, linkedin } = values;
    try {
      const res = await window.axios.post('/gql', {
        query,
        variables: {
          logo,
          description,
          website,
          facebook,
          twitter,
          instagram,
          linkedin
        }
      });
      if (res.data.data.createExhibitorByNew) {
        message.success('Add exhibitor successfully');
        this.setState({ modalVisible: false });
        this._getExhibitors();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Add exhibitor unsuccessfully');
    }

    this.setState({ isSubmitting: false });
  };

  _addExhibitorByExisting = async values => {
    this.setState({ isSubmitting: true });
    const query = `
			mutation {
			  createExhibitorByExisting(
			    companyId: "${values.companyId}"
			    eventId: "${this.props.location.state.event.id}"
			  ) {
			    id
			  }
			}
    `;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.createExhibitorByExisting) {
        message.success('Add exhibitor successfully');
        this.setState({ modalVisible: false });
        this._getExhibitors();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Add exhibitor unsuccessfully');
    }

    this.setState({ isSubmitting: false });
  };

  /**
   * This is only to remove the connect between exhibitor and event rather than deleting exhibitor
   * @param item
   * @returns {Promise<void>}
   * @private
   */
  _deleteExhibitor = async item => {
    const query = `
			mutation {
			  deleteExhibitor(id: "${item.id}") {
			    id
			  }
			}
		`;

    try {
      const res = await window.axios.post('/gql', { query });
      if (res.data.data.deleteExhibitor) {
        message.success('Delete exhibitor successfully');
        this._getExhibitors();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Delete exhibitor unsuccessfully');
    }
  };

  componentDidMount() {
    this._getAllCompanies();
    this._getExhibitors();
  }

  render() {
    const {
      initialData,
      modalVisible,
      isAddMode,
      exhibitors,
      isFetching,
      isSubmitting,
      allCompanies
    } = this.state;
    return (
      <div className="exhibitor">
        <div className="toolbar">
          <div />
          <Button
            type="primary"
            onClick={() => {
              this.setState({
                modalVisible: true,
                isAddMode: true,
                initialData: {}
              });
            }}>
            <Icon type="plus" /> Add Exhibitor
          </Button>
        </div>
        <ExhibitorTable
          loading={isFetching}
          data={exhibitors}
          onDelete={this._deleteExhibitor}
          onEdit={item => {
            this.setState({
              isAddMode: false,
              modalVisible: true,
              initialData: item.company
            });
          }}
        />
        <ExhibitorModal
          isSubmitting={isSubmitting}
          allCompanies={allCompanies}
          initialData={initialData}
          isAddMode={isAddMode}
          onCancel={() => this.setState({ modalVisible: false })}
          visible={modalVisible}
          onSubmit={this._onSubmit}
        />
      </div>
    );
  }
}

export default Exhibitor;
