/**
 * Created by Di Wang on 2019-01-03.
 */
import React from 'react';
import './presenter-tag.scss';
import PropTypes from 'prop-types';
import { Popconfirm, Tooltip } from 'antd';

export default class PresenterTag extends React.PureComponent {
    static propTypes = {
        /** delete onClick callback */
        deleteOnClick: PropTypes.func,

        /** user info */
        user: PropTypes.shape({
            /** user id */
            id: PropTypes.string,

            /** user name */
            name: PropTypes.string,

            /** user company */
            company: PropTypes.string
        })
    };

    static defaultProps = {
        deleteOnClick: () => {}
    };

    render() {
        const { user, deleteOnClick } = this.props;
        return (
            <span className="presenter-tag">
                <Tooltip title={user.company}>
                    <span className="presenter-tag__body">{user.name}</span>
                </Tooltip>
                <Popconfirm title="Are you sure?" onConfirm={deleteOnClick} cancelText="No">
                    <span className="presenter-tag__close-btn">x</span>
                </Popconfirm>
            </span>
        );
    }
}
