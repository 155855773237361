/**
 * Created by Di Wang on 2018-12-08.
 */
import React, { PureComponent } from 'react';
import './event-basic.scss';
import moment from 'moment';
import { Input, Form, DatePicker, Button, message, Select } from 'antd';
import RequiredTitle from '../../../component/RequiredTitle';
import MapView from '../../../component/MapView';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { mapboxToken } from '../../../constant/config';
import Countries from '../../../constant/country';
import UploadIndicator from '../../../component/UploadIndicator';
import Loader from '../../../component/Loader';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

class EventBasicWrapper extends PureComponent {
  state = {
    /** location geo for map view */
    locationGeo: null,

    /** logo */
    logo: null,

    /** logo indicator status */
    logoIndicatorStatus: UploadIndicator.STATUS.HIDDEN,

    /** cover image */
    cover: null,

    /** cover indicator status */
    coverIndicatorStatus: UploadIndicator.STATUS.HIDDEN,

    /** flag for submitting the form */
    isSubmitting: false,

    /** whether fetching data */
    isFetching: false
  };

  async componentDidMount() {
    await this._initData();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isSubmitting,
      logo,
      cover,
      locationGeo,
      logoIndicatorStatus,
      coverIndicatorStatus,
      isFetching
    } = this.state;
    return (
      <div className="event-basic">
        {isFetching && <Loader topRightCorner={true} size={20} />}
        <Form onSubmit={this._formSubmit}>
          <RequiredTitle required>Event Name</RequiredTitle>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Event name is required' }],
              initialValue: this.props.location.state.event.name
            })(<Input placeholder="Event name" />)}
          </FormItem>

          <RequiredTitle required>Join Code</RequiredTitle>
          <p className="event-basic__subtitle">
            Multiple join code supported. Use ',' to split them. Eg. UK20, UK2020
          </p>
          <FormItem>
            {getFieldDecorator('joinCode', {
              rules: [{ required: true, message: 'Join code is required' }]
            })(<Input placeholder="Join code" />)}
          </FormItem>

          <RequiredTitle required>Date</RequiredTitle>
          <FormItem>
            {getFieldDecorator('date', {
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Event date is required'
                }
              ]
            })(<DatePicker format="DD/MM/YYYY" className="event-basic__date-picker" />)}
          </FormItem>

          <RequiredTitle>Description</RequiredTitle>
          <p className="event-basic__subtitle">
            The description is only visible to users who are joined this event.
          </p>
          <FormItem>{getFieldDecorator('description', {})(<TextArea rows={5} />)}</FormItem>

          <RequiredTitle>Welcome Message</RequiredTitle>
          <p className="event-basic__subtitle">
            The description is visible to all users, making it a good marketing opportunity for your
            event!
          </p>
          <FormItem>{getFieldDecorator('welcomeMessage', {})(<TextArea rows={5} />)}</FormItem>

          <RequiredTitle>Location</RequiredTitle>
          <FormItem>
            <div className="event-basic__row">
              <div className="event-basic__location">
                <FormItem>
                  {getFieldDecorator('address1', {})(<Input placeholder="Address 1" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('address2', {})(<Input placeholder="Address 2" />)}
                </FormItem>
                <FormItem>{getFieldDecorator('city', {})(<Input placeholder="City" />)}</FormItem>
                <div className="event-basic__row">
                  <FormItem className="event-basic__column" style={{ marginRight: '5px' }}>
                    {getFieldDecorator('state', {})(<Input placeholder="State" />)}
                  </FormItem>
                  <FormItem className="event-basic__column" style={{ marginLeft: '5px' }}>
                    {getFieldDecorator('postcode', {})(<Input placeholder="Postcode" />)}
                  </FormItem>
                </div>
                <FormItem>
                  {getFieldDecorator('country', {})(
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      <Option disabled value={null}>
                        Select country
                      </Option>
                      {Countries.map(country => (
                        <Option value={country.name} key={country.name}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
                <div className="event-basic__check-btn" onClick={this._checkLocation}>
                  Check Location
                </div>
              </div>
              <MapView geometry={locationGeo} />
            </div>
          </FormItem>

          <RequiredTitle>Event Website URL</RequiredTitle>
          <FormItem>{getFieldDecorator('website', {})(<Input placeholder="Home page" />)}</FormItem>

          <RequiredTitle>Twitter Feed Link</RequiredTitle>
          <p className="event-basic__subtitle">
            Test preview page via{' '}
            <a
              href="https://publish.twitter.com/?query=https%3A%2F%2Ftwitter.com%2Fvumero%2F&widget=Timeline"
              target="_blank"
              rel="noopener noreferrer">
              https://publish.twitter.com/#
            </a>
          </p>
          <FormItem>
            {getFieldDecorator('twitterFeedLink', {})(
              <Input placeholder="Feed link. eg. https://twitter.com/vumero" />
            )}
          </FormItem>

          <RequiredTitle>Logo</RequiredTitle>
          <p className="event-basic__subtitle">
            Recommended dimensions: 256px X 256px. Accepted file types: jpg/jpeg, png.
          </p>
          <FormItem>
            <div
              className="event-basic__upload-wrapper event-basic__logo-upload-wrapper"
              style={{ borderStyle: logo ? 'solid' : 'dashed' }}>
              {logo ? (
                <img src={logo} className="event-basic__logo-img" alt="logo" />
              ) : (
                <p>+ Add Logo Image</p>
              )}
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg"
                className="event-basic__upload-input"
                onChange={this._logoUploadOnChange}
              />
              <UploadIndicator
                status={logoIndicatorStatus}
                className="event-basic__upload-indicator"
              />
            </div>
          </FormItem>

          <RequiredTitle>Cover Image</RequiredTitle>
          <p className="event-basic__subtitle">
            Recommended dimensions: 500px X 200px. Accepted file types: jpg/jpeg, png.
          </p>
          <FormItem>
            <div
              className="event-basic__upload-wrapper event-basic__cover-upload-wrapper"
              style={{ borderStyle: cover ? 'solid' : 'dashed' }}>
              {cover ? (
                <img src={cover} className="event-basic__logo-img" alt="cover" />
              ) : (
                <p>+ Add Cover Image</p>
              )}
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg"
                className="event-basic__upload-input"
                onChange={this._coverUploadOnChange}
              />
              <UploadIndicator
                status={coverIndicatorStatus}
                className="event-basic__upload-indicator"
              />
            </div>
          </FormItem>

          <Button
            htmlType="submit"
            loading={isSubmitting}
            type="primary"
            size="large"
            className="event-basic__save-btn">
            Save
          </Button>
        </Form>
      </div>
    );
  }

  _initData = async () => {
    this.setState({ isFetching: true });
    const query = `
	        {
			  event(id: "${this.props.location.state.event.id}") {
			    name
			    joinCode
			    date
			    description
			    welcomeMsg
			    address1
			    address2
			    city
			    state
			    postcode
			    country
			    twitterFeedLink
			    website
			    logo
			    coverImg
			  }
			}
    	`;

    try {
      const res = await window.axios.post('/gql', { query });
      // console.log(res.data);
      const event = res.data.data.event;
      if (res.data.data.event) {
        this.props.form.setFields({
          joinCode: { value: event.joinCode },
          date: { value: event.date ? moment(event.date) : null },
          description: { value: event.description },
          welcomeMessage: { value: event.welcomeMsg },
          address1: { value: event.address1 },
          address2: { value: event.address2 },
          city: { value: event.city },
          state: { value: event.state },
          postcode: { value: event.postcode },
          country: { value: event.country },
          website: { value: event.website },
          twitterFeedLink: { value: event.twitterFeedLink }
        });
        this.setState({
          logo: event.logo,
          cover: event.coverImg
        });
        this._checkLocation();
      } else {
        message.error(res.data.errors[0].message);
      }
    } catch (e) {
      message.error('Fetch data failed');
    }

    this.setState({ isFetching: false });
  };

  /**
   * Submit form data
   * @param e
   * @private
   */
  _formSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // console.log(values);
        this.setState({ isSubmitting: true });
        const query = `
					mutation(
					  $name: String
					  $date: DateTime
					  $description: String
					  $welcomeMsg: String
					  $joinCode: String
					  $address1: String
					  $address2: String
					  $city: String
					  $state: String
					  $country: String
					  $postcode: String
					  $website: String
					  $twitterFeedLink: String
					  $logo: String
					  $coverImg: String
					) {
					  updateEvent(
					    id: "${this.props.location.state.event.id}"
					    name: $name
					    date: $date
					    description: $description
					    welcomeMsg: $welcomeMsg
					    joinCode: $joinCode
					    address1: $address1
					    address2: $address2
					    city: $city
					    state: $state
					    country: $country
					    postcode: $postcode
					    website: $website
					    twitterFeedLink: $twitterFeedLink
					    logo: $logo
					    coverImg: $coverImg
					  ) {
					    id
					  }
					}
				`;

        try {
          const {
            name,
            description,
            date,
            welcomeMessage,
            joinCode,
            address1,
            address2,
            city,
            state,
            country,
            postcode,
            website,
            twitterFeedLink
          } = values;
          const res = await window.axios.post('/gql', {
            query,
            variables: {
              name,
              date: moment.utc([date.year(), date.month(), date.date()]).toISOString(),
              description,
              welcomeMsg: welcomeMessage,
              joinCode,
              address1,
              address2,
              city,
              state,
              country,
              postcode,
              website,
              twitterFeedLink,
              logo: this.state.logo,
              coverImg: this.state.cover
            }
          });
          if (res.data.errors) {
            message.error('Update failed. Please check your input');
          } else {
            message.success('Update successfully');
          }
        } catch (e) {
          message.error('Update unsuccessfully');
        }

        this.setState({ isSubmitting: false });
      }
    });
  };

  /**
   * Send query to mapbox to update map info
   * @private
   */
  _checkLocation = () => {
    const values = this.props.form.getFieldsValue();

    if (values.address1 && values.city) {
      const query = `${values.address1} ${values.address2} ${values.city} ${values.state} 
                        ${values.postcode} ${values.country}`;
      const geocodingClient = mbxGeocoding({ accessToken: mapboxToken });

      geocodingClient
        .forwardGeocode({
          query,
          limit: 1
        })
        .send()
        .then(response => {
          if (response.body.features.length > 0) {
            this.setState({ locationGeo: response.body.features[0].geometry });
          }
        });
    }
  };

  /**
   * Handle logo uploading logic
   * @param evt
   * @private
   */
  _logoUploadOnChange = async evt => {
    const file = evt.target.files[0];

    if (file) {
      this.setState({ logoIndicatorStatus: UploadIndicator.STATUS.LOADING });
      // payload form data
      const data = new FormData();
      data.append('file', file);
      data.append('folder', `${this.props.location.state.event.folderName}`);

      try {
        const res = await window.axios.post('/api/file/upload', data);
        // console.log(res.data);
        this.setState({
          logo: res.data.url,
          logoIndicatorStatus: UploadIndicator.STATUS.DONE
        });
      } catch (e) {
        this.setState({ logoIndicatorStatus: UploadIndicator.STATUS.FAILED });
      }
    }
  };

  /**
   * Handle cover image uploading logic
   * @param evt
   * @private
   */
  _coverUploadOnChange = async evt => {
    const file = evt.target.files[0];

    if (file) {
      this.setState({ coverIndicatorStatus: UploadIndicator.STATUS.LOADING });
      // payload form data
      const data = new FormData();
      data.append('file', file);
      data.append('folder', `${this.props.location.state.event.folderName}`);

      try {
        const res = await window.axios.post('/api/file/upload', data);
        // console.log(res.data);
        this.setState({
          cover: res.data.url,
          coverIndicatorStatus: UploadIndicator.STATUS.DONE
        });
      } catch (e) {
        this.setState({ coverIndicatorStatus: UploadIndicator.STATUS.FAILED });
      }
    }
  };
}

const EventBasic = Form.create()(EventBasicWrapper);

export default EventBasic;
