/**
 * Created by Di Wang on 2019-04-25.
 */
import React from 'react';
import { Icon, Switch, Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const PollTable = ({ dataSource, loading, onEdit, onDelete, onClick, onSwitch }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Proposer',
            dataIndex: 'proposer.firstName',
            align: 'center'
        },
        {
            title: 'Start Time',
            dataIndex: 'startAt',
            align: 'center',
            render: val => <span>{moment(val).format('DD/MM/YYYY - HH:mm')}</span>
        },
        {
            title: 'End Time',
            dataIndex: 'endAt',
            align: 'center',
            render: val => <span>{moment(val).format('DD/MM/YYYY - HH:mm')}</span>
        },
        {
            title: 'Pin',
            dataIndex: 'pin',
            align: 'center',
            render: (val, record) => (
                <Switch
                    defaultChecked={val}
                    size="small"
                    onChange={(checked, e) => {
                        e.stopPropagation();
                        onSwitch && onSwitch(record, checked);
                    }}
                />
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <span>
                    <Icon
                        onClick={e => {
                            e.stopPropagation();
                            onEdit(record);
                        }}
                        type="edit"
                        className="event-table__icon"
                        theme="twoTone"
                    />
                    <Icon
                        onClick={e => {
                            e.stopPropagation();
                            onDelete(record);
                        }}
                        type="delete"
                        className="event-table__icon"
                        theme="twoTone"
                    />
                </span>
            )
        }
    ];

    return (
        <Table
            rowKey={item => item.id}
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            onRow={(record, rowIndex) => ({
                onClick: () => onClick(record)
            })}
        />
    );
};

PollTable.propTypes = {
    dataSource: PropTypes.array,
    loading: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    onSwitch: PropTypes.func
};

export default PollTable;
