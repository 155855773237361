/**
 * Created by Di Wang on 2018-12-08.
 */
import React from 'react';
import './not-found.scss';
import { Link } from 'react-router';

const NotFound = () => (
    <div className="not-found">
        <div className="not-found__container">
            <img src={require('../../asset/img/404.png')} alt="404" className="not-found__img" />
            <div>
                <h1>The page is disappear</h1>
                <h3>
                    Go back <Link to="/">home</Link> to continue...
                </h3>
            </div>
        </div>
    </div>
);

export default NotFound;
