/**
 * Created by Di Wang on 2019-01-12.
 */
import React from 'react';
import AttendeeTable from './component/AttendeeTable';
import { Button, Icon, message } from 'antd';
import AttendeeModal from './component/AttendeeModal';
import BulkImportModal from './component/BulkImportModal';

export default class Attendee extends React.PureComponent {
    state = {
        isAddMode: true,
        modalVisible: false,
        bulkModalVisible: false,
        attendees: [],
        initialData: {},
        isSubmitting: false,
        isFetching: false
    };

    _getAttendees = async () => {
        this.setState({ isFetching: true });
        const query = `
			{
			  event(id: "${this.props.location.state.event.id}") {
			    attendees {
			      id
			      name
			      email
			      title
			      company
			    }
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.event) {
                this.setState({ attendees: res.data.data.event.attendees });
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Get attendee list failed');
        }
        this.setState({ isFetching: false });
    };

    _attendeeOnSubmit = async values => {
        this.setState({ isSubmitting: true });
        const { id, name, email, title, company } = values;
        const query = `
			mutation($id: ID!, $title: String, $company: String) {
			  upsertAttendee(
			    id: $id
			    name: "${name}"
			    email: "${email}"
			    title: $title
			    company: $company
			    event: "${this.props.location.state.event.id}"
			  ) {
			    id
			  }
			}
		`;
        try {
            const res = await window.axios.post('/gql', {
                query,
                variables: { id, title, company }
            });
            if (res.data.data.upsertAttendee) {
                this.setState({ modalVisible: false });
                this._getAttendees();
                message.success(
                    `${this.state.isAddMode ? 'Create' : 'Update'} attendee successful`
                );
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error(`${this.state.isAddMode ? 'Create' : 'Update'} attendee failed`);
        }

        this.setState({ isSubmitting: false });
    };

    _attendeeOnEdit = item => {
        this.setState({
            modalVisible: true,
            isAddMode: false,
            initialData: item
        });
    };

    _attendeeOnDelete = async item => {
        const query = `
			mutation {
			  deleteAttendee(id: "${item.id}") {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.deleteAttendee) {
                message.success('Delete attendee successful');
                this._getAttendees();
            } else {
                message.success(res.data.errors[0].message);
            }
        } catch (e) {
            message.success('Delete attendee failed');
        }
    };

    _bulkAttendeeOnSubmit = async data => {
        const arr = data.map(item => {
            const props = Object.keys(item)
                .map(key => `${key}: "${item[key]}"`)
                .join(',');
            return `{${props}}`;
        });
        const query = `
			mutation {
			  createManyAttendees(
			    eventId: "${this.props.location.state.event.id}"
			    attendees: [${arr}]
			  ) {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.createManyAttendees) {
                message.success('Bulk import attendee successful');
                this._getAttendees();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Bulk import attendee failed');
        }

        this.setState({ bulkModalVisible: false });
    };

    componentDidMount() {
        this._getAttendees();
    }

    render() {
        const {
            modalVisible,
            attendees,
            initialData,
            isSubmitting,
            isFetching,
            isAddMode,
            bulkModalVisible
        } = this.state;
        return (
            <div className="attendee">
                <div className="toolbar">
                    <div />
                    <div>
                        <Button
                            type="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => this.setState({ bulkModalVisible: true })}>
                            <Icon type="plus" /> Bulk Import
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                this.setState({
                                    modalVisible: true,
                                    isAddMode: true,
                                    initialData: {}
                                });
                            }}>
                            <Icon type="plus" /> Add Attendee
                        </Button>
                    </div>
                </div>
                <AttendeeTable
                    onEdit={this._attendeeOnEdit}
                    onDelete={this._attendeeOnDelete}
                    loading={isFetching}
                    data={attendees}
                />
                <AttendeeModal
                    visible={modalVisible}
                    isAddMode={isAddMode}
                    onCancel={() => this.setState({ modalVisible: false })}
                    onSubmit={this._attendeeOnSubmit}
                    defaultValue={initialData}
                    isSubmitting={isSubmitting}
                />
                <BulkImportModal
                    visible={bulkModalVisible}
                    onCancel={() => this.setState({ bulkModalVisible: false })}
                    onSubmit={this._bulkAttendeeOnSubmit}
                />
            </div>
        );
    }
}
