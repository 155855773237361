/**
 * Created by Di Wang on 2019-01-05.
 */
import React from 'react';
import { Icon, Popconfirm } from 'antd';
import './agenda-file-tag.scss';
import PropTypes from 'prop-types';

export default class FileTag extends React.PureComponent {
    static propTypes = {
        file: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            url: PropTypes.string,
            key: PropTypes.string
        }),
        addBtnOnClick: PropTypes.func,
        editBtnOnClick: PropTypes.func,
        deleteBtnOnClick: PropTypes.func
    };

    static defaultProps = {
        editBtnOnClick: () => {},
        deleteBtnOnClick: () => {}
    };

    render() {
        const { file, addBtnOnClick, editBtnOnClick, deleteBtnOnClick } = this.props;
        return (
            <span className="agenda-file-tag">
                {file ? (
                    <span>
                        <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="agenda-file-tag__file">
                            <Icon type="file-pdf" /> {file.name}
                        </a>
                        <span className="agenda-file-tag__btn" onClick={editBtnOnClick}>
                            <Icon type="edit" />
                        </span>
                        <Popconfirm
                            title="Are you sure?"
                            cancelText="No"
                            onConfirm={deleteBtnOnClick}>
                            <span className="agenda-file-tag__btn">
                                <Icon type="delete" />
                            </span>
                        </Popconfirm>
                    </span>
                ) : (
                    <span className="agenda-file-tag__add-btn" onClick={addBtnOnClick}>
                        + Add pdf file
                    </span>
                )}
            </span>
        );
    }
}
