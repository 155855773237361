/**
 * @author Di Wang
 */
import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import PropTypes from 'prop-types';

export default class BarChart extends React.PureComponent {
  static props = {
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
      })
    ),
    loading: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    loading: true
  };

  state = {
    height: 100,
    loading: true
  };

  componentDidMount() {
    this.container = echarts.init(document.getElementById(this.props.id), 'wonderland');
    this.container.showLoading();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return {
        height: props.data.length * 15 + 120,
        loading: props.loading
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: Object.values(data).map(item => item.name)
      },
      series: [
        {
          data: Object.values(data).map(item => item.value),
          type: 'bar'
        }
      ]
    };
    this.container.hideLoading();
    this.container.setOption(option);
    this.container.resize();
  }

  render() {
    const { id, title } = this.props;
    return (
      <div className="pie-chart__container">
        <h4 className="pie-chart__title">{title}</h4>
        <div id={id} style={{ width: 800, height: this.state.height }} />
      </div>
    );
  }
}
