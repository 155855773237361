/**
 * Created by Di Wang on 2019-07-13.
 */
import React from 'react';
import { Icon, Popconfirm, Table } from 'antd';

const AttendeeTable = ({ loading, data, onEdit, onDelete }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Title',
            dataIndex: 'title'
        },
        {
            title: 'Company',
            dataIndex: 'company'
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <span>
                    <Icon
                        onClick={() => onEdit(record)}
                        type="edit"
                        className="event-table__icon"
                        theme="twoTone"
                    />
                    <Popconfirm title="Are you sure?" onConfirm={() => onDelete(record)}>
                        <Icon type="delete" theme="twoTone" className="event-table__icon" />
                    </Popconfirm>
                </span>
            )
        }
    ];

    return <Table loading={loading} columns={columns} dataSource={data} rowKey={item => item.id} />;
};

export default AttendeeTable;
