/**
 * Created by Di Wang on 2018-12-07.
 */
import React, { PureComponent } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { SwatchesPicker } from 'react-color';
import './track-modal.scss';

const { create } = Form;
const FormItem = Form.Item;

@create()
class TrackModal extends PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,

        /** flag for the submit type */
        isAddMode: PropTypes.bool,

        /** update mode only, set for initial value for form */
        defaultValue: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            color: PropTypes.string
        }),

        onSubmit: PropTypes.func
    };

    static defaultProps = {
        onSubmit: () => {},
        onCancel: () => {},
        isAddMode: true
    };

    state = {
        defaultColor: '#2196f3',
        color: '#2196f3'
    };

    static getDerivedStateFromProps(nextProps, preState) {
        if (nextProps.defaultValue && nextProps.defaultValue.color !== preState.defaultColor) {
            return {
                defaultColor: nextProps.defaultValue.color,
                color: nextProps.defaultValue.color
            };
        }
        return null;
    }

    render() {
        const { visible, onCancel, isAddMode, form, defaultValue, isSubmitting } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                title={`${isAddMode ? 'Add' : 'Update'} Track`}
                onCancel={onCancel}
                footer={null}
                destroyOnClose={true}
                maskClosable={false}
                visible={visible}>
                <Form onSubmit={this._formSubmit}>
                    <FormItem>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Track name is required.' }],
                            initialValue: defaultValue ? defaultValue.name : ''
                        })(<Input placeholder="Track Name" size="large" />)}
                    </FormItem>
                    <p className="track-modal__color-label">
                        <span>Track Tag Color</span>
                        <span
                            className="track-modal__color-block"
                            style={{ backgroundColor: this.state.color }}
                        />
                    </p>
                    <SwatchesPicker
                        width="100%"
                        height={150}
                        color={this.state.color}
                        onChange={this._colorOnChange}
                    />
                    <Button
                        style={{ marginTop: '25px' }}
                        htmlType="submit"
                        type="primary"
                        block
                        loading={isSubmitting}
                        size="large">
                        {this.props.isAddMode ? 'ADD' : 'UPDATE'}
                    </Button>
                </Form>
            </Modal>
        );
    }

    _colorOnChange = color => {
        this.setState({ color: color.hex });
    };

    _formSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const payload = this.props.isAddMode
                    ? { ...values, color: this.state.color, id: '' }
                    : { ...values, color: this.state.color, id: this.props.defaultValue.id };
                this.props.onSubmit(payload);
            }
        });
    };
}

export default TrackModal;
