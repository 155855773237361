/**
 * Created by Di Wang on 2018-12-16.
 */
import React, { PureComponent } from 'react';
import './session-modal.scss';
import { Modal, Button, Form, Input, TimePicker, Select, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { Item, create } = Form;
const { Option } = Select;
const { TextArea } = Input;

@create()
class SessionModal extends PureComponent {
  static propTypes = {
    isAddMode: PropTypes.bool,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,

    /** tracks info */
    tracks: PropTypes.array,

    /** default session data */
    defaultValue: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      summary: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      trackId: PropTypes.string
    }),

    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func,

    defaultEventDate: PropTypes.string
  };

  static defaultProps = {
    onCancel: () => {},
    onSubmit: () => {}
  };

  render() {
    const {
      visible,
      onCancel,
      isAddMode,
      defaultValue,
      tracks,
      isSubmitting,
      defaultEventDate
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={isAddMode ? 'Add Session' : 'Edit Session'}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCancel}
        visible={visible}>
        <Form onSubmit={this._submitForm} className="session-modal">
          <Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Session name is required' }],
              initialValue: defaultValue ? defaultValue.name : ''
            })(<Input placeholder="Session name" />)}
          </Item>
          <Item>
            {getFieldDecorator('date', {
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Please select date'
                }
              ],
              initialValue: isAddMode
                ? moment(defaultEventDate).utc()
                : defaultValue
                ? moment(defaultValue.startTime).utc()
                : null
            })(
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
                placeholder="Session date"
              />
            )}
          </Item>
          <div className="session-modal__row">
            <div className="session-modal__column">
              <Item>
                {getFieldDecorator('startTime', {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select time'
                    }
                  ],
                  initialValue: defaultValue ? moment(defaultValue.startTime).utc() : null
                })(
                  <TimePicker
                    minuteStep={5}
                    placeholder="Start time"
                    format="HH:mm"
                    className="session-modal__time-picker"
                  />
                )}
              </Item>
            </div>
            <div className="session-modal__column">
              <Item>
                {getFieldDecorator('endTime', {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select time'
                    }
                  ],
                  initialValue: defaultValue ? moment(defaultValue.endTime).utc() : null
                })(
                  <TimePicker
                    minuteStep={5}
                    placeholder="End time"
                    format="HH:mm"
                    className="session-modal__time-picker"
                  />
                )}
              </Item>
            </div>
          </div>
          <Item>
            {getFieldDecorator('summary', {
              initialValue: defaultValue && defaultValue.summary ? defaultValue.summary : ''
            })(<TextArea rows={3} placeholder="Something about this session..." />)}
          </Item>
          <Item>
            {getFieldDecorator('trackId', {
              initialValue: defaultValue && defaultValue.trackId ? defaultValue.trackId : null
            })(
              <Select placeholder="Select track">
                <Option value={null}>No track info</Option>
                {tracks.map(track => (
                  <Option key={track.id} value={track.id}>
                    <span
                      className="session-modal__track-dot"
                      style={{ backgroundColor: track.color }}
                    />
                    {track.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Button loading={isSubmitting} htmlType="submit" type="primary" block>
            {isAddMode ? 'ADD' : 'UPDATE'}
          </Button>
        </Form>
      </Modal>
    );
  }

  _submitForm = e => {
    e.preventDefault();
    const { form, isAddMode, defaultValue } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        let { date, startTime, endTime } = values;
        const startTimeUTC = moment.utc([
          date.get('year'),
          date.get('month'),
          date.get('date'),
          startTime.hour(),
          startTime.minute(),
          0,
          0
        ]);
        const endTimeUTC = moment.utc([
          date.get('year'),
          date.get('month'),
          date.get('date'),
          endTime.hour(),
          endTime.minute(),
          0,
          0
        ]);
        const payload = isAddMode
          ? { ...values, id: '', startTime: startTimeUTC, endTime: endTimeUTC }
          : { ...values, id: defaultValue.id, startTime: startTimeUTC, endTime: endTimeUTC };
        this.props.onSubmit(payload);
      }
    });
  };
}

export default SessionModal;
