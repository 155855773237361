/**
 * Created by Di Wang on 2018-12-08.
 */
import React from 'react';
import './required-title.scss';
import PropTypes from 'prop-types';

/**
 * A title with required flag
 * @param props
 * @returns {*}
 * @constructor
 */
const RequiredTitle = props => (
    <h3 className="required-title">
        {props.children}
        {props.required && <span className="required-title__star">*</span>}
    </h3>
);

RequiredTitle.propTypes = {
    required: PropTypes.bool
};

export default RequiredTitle;
