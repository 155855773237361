/**
 * Created by Di Wang on 2019-05-09.
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
// import PollChart from '../PollChart';

const PollDetail = props => {
  const { visible, onCancel, data } = props;

  return (
    <Modal
      maskClosable={false}
      footer={null}
      title="Poll Details"
      destroyOnClose={true}
      visible={visible}
      onCancel={onCancel}>
      {/*<PollChart data={data} />*/}
    </Modal>
  );
};

PollDetail.propTypes = {
  data: PropTypes.array,
  visible: PropTypes.bool,
  onCancel: PropTypes.func
};

export default PollDetail;
