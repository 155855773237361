/**
 * Created by Di Wang on 2019-05-10.
 */
import React from 'react';
import { Table } from 'antd';

const CompanyTable = ({ onEdit, onDelete, loading, data }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Website',
            dataIndex: 'website'
        },
        {
            title: 'Social Media',
            dataIndex: ''
        }
        // {
        //     title: 'Action',
        //     align: 'center',
        //     render: (text, record) => (
        //         <span>
        //             <Icon
        //                 onClick={e => {
        //                     e.stopPropagation();
        //                     onEdit && onEdit(record);
        //                 }}
        //                 type="edit"
        //                 className="event-table__icon"
        //                 theme="twoTone"
        //             />
        //             <Icon
        //                 onClick={e => {
        //                     e.stopPropagation();
        //                     onDelete && onDelete(record);
        //                 }}
        //                 type="delete"
        //                 className="event-table__icon"
        //                 theme="twoTone"
        //             />
        //         </span>
        //     )
        // }
    ];

    return (
        <Table
            className="track-table"
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={item => item.id}
        />
    );
};

export default CompanyTable;
