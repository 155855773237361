/**
 * Created by Di Wang on 2018-12-10.
 */
import React from 'react';
import './session-item.scss';
import { Icon, Tag, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import PresenterTag from '../PresenterTag';
import FileTag from '../FileTag';
import AudioTag from '../AudioTag';

export default class SessionItem extends React.PureComponent {
  static propTypes = {
    editOnClick: PropTypes.func,
    deleteOnClick: PropTypes.func,
    fileAddBtnOnClick: PropTypes.func,
    fileEditBtnOnClick: PropTypes.func,
    fileDeleteBtnOnClick: PropTypes.func,
    audioAddBtnOnClick: PropTypes.func,
    audioEditBtnOnClick: PropTypes.func,
    audioDeleteBtnOnClick: PropTypes.func,
    presenterAddBtnOnClick: PropTypes.func,
    presenterDeleteBtnOnClick: PropTypes.func,

    /** GQL data structure type */
    item: PropTypes.shape({
      id: PropTypes.string, //session id
      name: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      summary: PropTypes.string,
      track: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        color: PropTypes.string
      }),
      slide: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        key: PropTypes.string
      }),
      audio: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string
      }),
      presenters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          role: PropTypes.string,
          user: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      )
    })
  };

  static defaultProps = {
    item: null,
    editOnClick: () => {},
    deleteOnClick: () => {}
  };

  _renderPresenters = role => {
    const { item } = this.props;
    const roles = item.presenters.filter(presenter => presenter.role === role);
    if (roles.length > 0) {
      return (
        <div className="session-item__presenter-row">
          <Icon type="user" /> {role.charAt(0) + role.toLowerCase().slice(1)}:
          {item.presenters
            .filter(presenter => presenter.role === role)
            .map(item => (
              <PresenterTag
                key={item.id}
                user={item.user}
                deleteOnClick={() => this.props.presenterDeleteBtnOnClick(item.id)}
              />
            ))}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      editOnClick,
      deleteOnClick,
      fileAddBtnOnClick,
      fileEditBtnOnClick,
      fileDeleteBtnOnClick,
      audioAddBtnOnClick,
      audioEditBtnOnClick,
      audioDeleteBtnOnClick,
      presenterAddBtnOnClick,
      item
    } = this.props;
    return (
      <div className="session-item">
        <h4>
          {item.name}
          {item.track && (
            <Tag color={item.track.color} className="session-item__tag">
              {item.track.name}
            </Tag>
          )}
        </h4>
        <p>
          <Icon type="clock-circle" />{' '}
          {moment(item.startTime)
            .utc()
            .format('hh:mm A')}{' '}
          -{' '}
          {moment(item.endTime)
            .utc()
            .format('hh:mm A')}
          <span className="session-item__add-btn" onClick={() => presenterAddBtnOnClick(item.id)}>
            + Add presenter
          </span>
          <FileTag
            file={item.slide}
            addBtnOnClick={() => fileAddBtnOnClick(item.id)}
            editBtnOnClick={() => fileEditBtnOnClick(item.id, item.slide)}
            deleteBtnOnClick={() => fileDeleteBtnOnClick(item.slide)}
          />
          <AudioTag
            audio={item.audio}
            addBtnOnClick={() => audioAddBtnOnClick(item.id)}
            editBtnOnClick={() => audioEditBtnOnClick(item.id, item.audio)}
            deleteBtnOnClick={() => audioDeleteBtnOnClick(item.audio)}
          />
        </p>
        <p>{item.summary}</p>
        {this._renderPresenters('SPEAKER')}
        {this._renderPresenters('MODERATOR')}
        {this._renderPresenters('PANELIST')}

        <div className="session-item__action-group">
          <Icon
            type="edit"
            theme="twoTone"
            className="session-item__icon"
            onClick={() => {
              editOnClick(item);
            }}
          />
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteOnClick(item)}>
            <Icon type="delete" theme="twoTone" className="session-item__icon" />
          </Popconfirm>
        </div>
      </div>
    );
  }
}
