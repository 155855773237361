/**
 * Created by Di Wang on 2018-12-08.
 */
import React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { mapboxToken } from '../../constant/config';

mapboxgl.accessToken = mapboxToken;

export default class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.map = null;
    }

    static propTypes = {
        geometry: PropTypes.object
    };

    static defaultProps = {
        geometry: null
    };

    _initMap = (lngLat = [144.9632, -37.8142]) => {
        this.map = new mapboxgl.Map({
            container: 'vm-map-view',
            style: 'mapbox://styles/mapbox/streets-v10',
            center: lngLat,
            zoom: 13
        });

        this.map.on('load', () => {
            this.map.addSource('single-point', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });

            this.map.addLayer({
                id: 'point',
                source: 'single-point',
                type: 'circle',
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#007cbf'
                }
            });
        });
    };

    componentDidMount() {
        this._initMap();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.geometry !== null) {
            const source = this.map.getSource('single-point');
            source && source.setData(nextProps.geometry);
            this.map.setZoom(15);
            this.map.flyTo({ center: nextProps.geometry.coordinates });
        }

        return false;
    }

    render() {
        return <div id="vm-map-view" style={{ width: '300px', height: '240px' }} />;
    }
}
