/**
 * Created by Di Wang on 2019-01-07.
 */
import React from 'react';
import { Icon, Modal, Table, Switch } from 'antd';
import PropTypes from 'prop-types';

export default class TopicTable extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array,
        loading: PropTypes.bool,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onPinSwitch: PropTypes.func,
        onLockSwitch: PropTypes.func
    };

    columns = [
        {
            title: 'Topic',
            dataIndex: 'name'
        },
        {
            title: 'Pin',
            dataIndex: 'pin',
            align: 'center',
            render: (val, record) => (
                <Switch
                    defaultChecked={val}
                    size="small"
                    onChange={checked => {
                        const { onPinSwitch } = this.props;
                        onPinSwitch && onPinSwitch(record, checked);
                    }}
                />
            )
        },
        {
            title: 'Lock',
            dataIndex: 'lock',
            align: 'center',
            render: (val, record) => (
                <Switch
                    defaultChecked={val}
                    size="small"
                    onChange={checked => {
                        const { onLockSwitch } = this.props;
                        onLockSwitch && onLockSwitch(record, checked);
                    }}
                />
            )
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <span>
                    <Icon
                        onClick={e => this.props.onEdit(record)}
                        type="edit"
                        className="event-table__icon"
                        theme="twoTone"
                    />
                    <Icon
                        onClick={this._deleteBtnOnClick.bind(this, record.id)}
                        type="delete"
                        theme="twoTone"
                        className="event-table__icon"
                    />
                </span>
            )
        }
    ];

    render() {
        const { data, loading } = this.props;
        return (
            <Table
                loading={loading}
                dataSource={data}
                columns={this.columns}
                rowKey={item => item.id}
            />
        );
    }

    _deleteBtnOnClick = id => {
        Modal.confirm({
            title: 'Are you sure to delete this topic',
            content: 'If yes, all discussion threads will be deleted as well.',
            onOk: () => {
                const { onDelete } = this.props;
                onDelete && onDelete(id);
            }
        });
    };
}
