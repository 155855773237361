/**
 * Created by Di Wang on 2019-07-08.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './submit-result.scss';

const Loading = ({ loadingLabel }) => (
    <>
        <Icon type="sync" style={{ color: 'rgb(24, 144, 255)', fontSize: 60 }} spin />
        <h3 className="submit-result__result-title">{loadingLabel}</h3>
        <p className="submit-result__result-desc">
            Do not refresh the page until you got the result.
        </p>
    </>
);

const Success = () => (
    <>
        <Icon type="check-circle" theme="filled" style={{ color: '#52c41a', fontSize: 60 }} />
        <h3 className="submit-result__result-title">Submit Successful</h3>
        <p className="submit-result__result-desc" />
    </>
);

const Error = () => (
    <>
        <Icon type="close-circle" theme="filled" style={{ color: '#f30', fontSize: 60 }} />
        <h3 className="submit-result__result-title">Opps, something wrong</h3>
        <p className="submit-result__result-desc">Check your file and try to submit again.</p>
    </>
);

export default class SubmitResult extends React.PureComponent {
    static propTypes = {
        status: PropTypes.oneOf(['loading', 'success', 'error']),
        loadingLabel: PropTypes.string
    };

    static defaultProps = {
        status: 'loading'
    };

    render() {
        const { status, loadingLabel } = this.props;
        return (
            <div className="submit-result">
                {status === 'loading' && <Loading loadingLabel={loadingLabel} />}
                {status === 'success' && <Success />}
                {status === 'error' && <Error />}
            </div>
        );
    }
}
