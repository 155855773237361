/**
 * Created by Di Wang on 2018-12-05.
 */
import React, { PureComponent } from 'react';
import './header.scss';
import { Modal, Icon } from 'antd';
import { Link, browserHistory } from 'react-router';
import * as PropTypes from 'prop-types';
import { version } from '../../../../package.json';

export default class Header extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatar: PropTypes.string
    })
  };

  static defaultProps = {
    user: {
      name: 'Unknown User',
      avatar: require('../../../asset/img/avatar-placeholder.png')
    }
  };

  render() {
    const { user } = this.props;
    return (
      <header className="header">
        <div className="header__container">
          <div className="header__menu">
            <div>
              <Link to="/">
                <img src={require('../../../asset/logo/logo-full.png')} alt="logo" width={110} />
              </Link>
              <div className="header__version">Version {version}</div>
            </div>
            <nav className="header__nav">
              <div className="header__nav-li">
                <Link to="/event">
                  <Icon type="project" /> Event
                </Link>
              </div>
              {/*<div className="header__nav-li">*/}
              {/*    <Link to="/user">*/}
              {/*        <Icon type="user" /> User*/}
              {/*    </Link>*/}
              {/*</div>*/}
              {/*<div className="header__nav-li">*/}
              {/*    <Link to="/organisation">*/}
              {/*        <Icon type="bank" /> Organisation*/}
              {/*    </Link>*/}
              {/*</div>*/}
              <div className="header__nav-li">
                <Link to="/track">
                  <Icon type="flag" /> Track
                </Link>
              </div>
              <div className="header__nav-li">
                <Link to="/notification">
                  <Icon type="notification" /> Notification
                </Link>
              </div>
            </nav>
          </div>
          <div className="header__right">
            <img
              className="header__avatar"
              src={user.avatar ? user.avatar : require('../../../asset/img/avatar-placeholder.png')}
              alt="avatar"
              width={35}
            />
            <span style={{ marginLeft: '5px' }}>
              {user.firstName} {user.lastName}
            </span>
            <Icon type="logout" onClick={this._logout} className="header__logout" />
          </div>
        </div>
      </header>
    );
  }

  _logout = () => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure to log out?',
      onOk: () => {
        localStorage.removeItem('token');
        browserHistory.replace('/login');
      }
    });
  };
}
