/**
 * Created by Di Wang on 2018-12-06.
 */
import EventBasic from './Basic';
import EventResource from './Resource';
import EventAgenda from './Agenda';
import EventSponsor from './Sponsor';
import EventExhibitor from './Exhibitor';
import EventDiscussionBoard from './DiscussionBoard';
import EventPoll from './Poll';
import EventAttendee from './Attendee';
import EventDashboard from './Dashboard';

export default [
    {
        name: 'Basic',
        route: 'basic',
        component: EventBasic
    },
    {
        name: 'Resource',
        route: 'resource',
        component: EventResource
    },
    {
        name: 'Agenda',
        route: 'agenda',
        component: EventAgenda
    },
    {
        name: 'Sponsor',
        route: 'sponsor',
        component: EventSponsor
    },
    {
        name: 'Exhibitor',
        route: 'exhibitor',
        component: EventExhibitor
    },
    {
        name: 'Discussion Board',
        route: 'discussion-board',
        component: EventDiscussionBoard
    },
    {
        name: 'Poll',
        route: 'poll',
        component: EventPoll
    },
    {
        name: 'Attendee',
        route: 'attendee',
        component: EventAttendee
    },
    {
        name: 'Dashboard',
        route: 'dashboard',
        component: EventDashboard
    }
];
