/**
 * Created by Di Wang on 2019-04-25.
 */
import React from 'react';
import PollTable from './PollTable';
import { Button, Icon, message, Modal } from 'antd';
import PollModal from './PollModal';
import PollDetail from './PollDetail';

const calcBallotCount = (name, options, ballots) => {
    const ballot = {};
    ballot.name = name;

    for (let option of options) {
        ballot[option] = 0;
    }

    for (let obj of ballots) {
        const { option } = obj;
        ballot[option] = ballot[option] + 1;
    }

    return [ballot];
};

export default class PollPage extends React.PureComponent {
    state = {
        modalVisible: false,
        detailVisible: false,
        isFetching: false,
        isSubmitting: false,
        polls: [],
        isAddMode: true,
        initialData: {},
        // ballot data for displaying diagram
        ballot: []
    };

    componentDidMount() {
        this._getPolls();
    }

    _getPolls = async () => {
        this.setState({ isFetching: true });
        const query = `
            {
              event(id: "${this.props.location.state.event.id}") {
                polls {
                  id
                  name
                  description
                  pin
                  proposer {
                    firstName
                    lastName
                  }
                  options
                  startAt
                  endAt
                }
              }
            }
        `;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.event) {
                this.setState({ polls: res.data.data.event.polls });
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Get poll list failed');
        }

        this.setState({ isFetching: false });
    };

    _onSubmit = async values => {
        this.setState({ isSubmitting: true });
        const {
            id,
            name,
            description,
            startAt,
            endAt,
            option1,
            option2,
            option3,
            option4
        } = values;
        const options = [option1, option2];
        option3 && options.push(option3);
        option4 && options.push(option4);

        const query = `
	        mutation($pollId: ID!) {
			  upsertPoll(
			    id: $pollId
			    name: "${name}"
			    description: "${description}"
			    options: ${JSON.stringify(options)}
			    eventId: "${this.props.location.state.event.id}"
			    startAt: "${startAt.toISOString()}"
			    endAt: "${endAt.toISOString()}"
			  ) {
			    id
			  }
			}
        `;

        const { isAddMode } = this.state;
        try {
            const res = await window.axios.post('/gql', {
                query,
                variables: { pollId: id }
            });

            if (res.data.data.upsertPoll) {
                message.success(`${isAddMode ? 'Create' : 'Update'} poll successful`);
                this.setState({ modalVisible: false });
                this._getPolls();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error(`${isAddMode ? 'Create' : 'Update'} poll failed`);
        }

        this.setState({ isSubmitting: false });
    };

    _onDelete = async item => {
        const query = `
			mutation {
			  deletePoll(id: "${item.id}") {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.deletePoll) {
                message.success('Delete poll successful');
                this._getPolls();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Delete poll failed');
        }
    };

    _onSwitch = async (item, checked) => {
        const query = `
            mutation {
              updatePollOnPin(id: "${item.id}", pin: ${checked}) {
                id
              }
            }
        `;
        try {
            await window.axios.post('/gql', { query });
        } catch (e) {}
    };

    _onClick = async item => {
        const query = `
			{
			  poll(id: "${item.id}") {
			    id
			    name
			    options
			    ballots {
			      option
			    }
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            const { poll } = res.data.data;
            if (poll) {
                const ballot = calcBallotCount(poll.name, poll.options, poll.ballots);
                console.log(ballot);
                this.setState({ ballot, detailVisible: true });
            }
        } catch (e) {}
    };

    render() {
        const {
            isAddMode,
            modalVisible,
            detailVisible,
            polls,
            isFetching,
            isSubmitting,
            initialData,
            ballot
        } = this.state;
        return (
            <div className="poll">
                <div className="toolbar">
                    <div />
                    <Button
                        type="primary"
                        onClick={() => {
                            this.setState({
                                isAddMode: true,
                                modalVisible: true,
                                initialData: {}
                            });
                        }}>
                        <Icon type="plus" /> Add Poll
                    </Button>
                </div>
                <PollTable
                    loading={isFetching}
                    dataSource={polls}
                    onEdit={item => {
                        this.setState({
                            isAddMode: false,
                            modalVisible: true,
                            initialData: item
                        });
                    }}
                    onDelete={item => {
                        Modal.confirm({
                            title: 'Are you sure to delete this poll?',
                            content: 'If yes, all ballots will be deleted as well.',
                            onOk: () => {
                                this._onDelete(item);
                            }
                        });
                    }}
                    onClick={this._onClick}
                    onSwitch={this._onSwitch}
                />
                <PollModal
                    initialData={initialData}
                    isSubmitting={isSubmitting}
                    isAddMode={isAddMode}
                    onSubmit={this._onSubmit}
                    visible={modalVisible}
                    onCancel={() => this.setState({ modalVisible: false })}
                />
                <PollDetail
                    data={ballot}
                    visible={detailVisible}
                    onCancel={() => this.setState({ detailVisible: false })}
                />
            </div>
        );
    }
}
