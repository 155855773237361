/**
 * Created by Di Wang on 2018-12-09.
 */
import React from 'react';
import './editor.scss';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import PropTypes from 'prop-types';

const excludeControls = [
    'strike-through',
    'superscript',
    'subscript',
    'remove-styles',
    'text-indent'
];

export default class Editor extends React.PureComponent {
    static propTypes = {
        folderName: PropTypes.string,
        defaultContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        editorOnChange: PropTypes.func,
        style: PropTypes.object
    };

    static defaultProps = {
        folderName: '',
        defaultContent: null,
        editorOnChange: null
    };

    state = {
        editorState: BraftEditor.createEditorState(null)
    };

    async componentDidMount() {
        const { defaultContent } = this.props;
        defaultContent &&
            this.setState({
                editorState: BraftEditor.createEditorState(defaultContent)
            });
    }

    _uploadFunc = async param => {
        const { folderName } = this.props;
        const data = new FormData();
        data.append('file', param.file);
        data.append('folder', `${folderName}`);

        try {
            const res = await window.axios.post('/api/file/upload', data);
            param.success({
                url: res.data.url,
                meta: {
                    id: res.data.fileKey,
                    title: res.data.fileKey
                }
            });
        } catch (e) {
            param.error({
                msg: 'Unable to upload.'
            });
        }
    };

    media = {
        uploadFn: this._uploadFunc,
        accepts: {
            image: 'image/png, image/jpeg',
            video: false,
            audio: false
        },
        externals: {
            embed: false,
            audio: false
        },
        pasteImage: true
    };

    _handleEditorChange = editorState => {
        this.setState({ editorState });
        this.props.editorOnChange && this.props.editorOnChange(editorState);
    };

    render() {
        const { editorState } = this.state;
        return (
            <BraftEditor
                className="editor"
                style={this.props.style}
                contentStyle={{ height: '250px' }}
                value={editorState}
                onChange={this._handleEditorChange}
                language="en"
                excludeControls={excludeControls}
                media={this.media}
            />
        );
    }
}
