/**
 * Created by Di Wang on 2019-02-26.
 */
const UPDATE_TRACKS = 'UPDATE_TRACKS';
const UPDATE_STATIC_USERS = 'UPDATE_STATIC_USERS';

const initialState = {
    tracks: [],
    staticUsers: []
};

export default function db(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TRACKS:
            return {
                ...state,
                tracks: action.data
            };

        case UPDATE_STATIC_USERS:
            return {
                ...state,
                staticUsers: action.data
            };

        default:
            return state;
    }
}

export function updateTracks(data) {
    return {
        data,
        type: UPDATE_TRACKS
    };
}

export function updateStaticUsers(data) {
    return {
        data,
        type: UPDATE_STATIC_USERS
    };
}
