/**
 * Created by Di Wang on 2019-03-01.
 */
import React from 'react';
import './notification-tutorial.scss';

const NotificationTutorial = () => {
    return (
        <div className="notification-tutorial">
            <p className="notification-tutorial__paragraph">
                1. Go to{' '}
                <a
                    href="https://console.firebase.google.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    https://console.firebase.google.com
                </a>{' '}
                to open the notification sending page in <b>Cloud Messaging</b> tab.
            </p>
            <img
                src={require('../../../asset/tutorial/tutorial0.png')}
                alt="tutorial"
                width={150}
            />
            <p className="notification-tutorial__paragraph">
                2. Click <b>New notification</b> button into the creation page. Enter{' '}
                <b>Notification title</b> and <b>Notification text</b> fields.
            </p>
            <img
                src={require('../../../asset/tutorial/tutorial1.png')}
                alt="tutorial"
                width={350}
            />
            <p className="notification-tutorial__paragraph">
                3.1 For notifying <b>all users</b>, select <b>User segment</b> button, add both iOS
                and Android platform.
            </p>
            <img
                src={require('../../../asset/tutorial/tutorial5.png')}
                alt="tutorial"
                width={600}
            />
            <p className="notification-tutorial__paragraph">
                3.2 For notifying <b>specific event users</b>, select <b>Topic</b> button, add{' '}
                <b>event id</b> in the field. Event id can be found in the event table.
            </p>
            <img
                src={require('../../../asset/tutorial/tutorial2.png')}
                alt="tutorial"
                width={350}
            />
            <p className="notification-tutorial__paragraph">
                4. Because Android platform cannot get title and message body when the user click
                the notification, there is a one more step to set title and message. Add a{' '}
                <b>Key</b> filed and type <b>title</b>, and the <b>Value</b> is set the title
                content. Add a <b>Key</b> field and type <b>msg</b>, the <b>Value</b> field is the
                message content.
            </p>
            <img
                src={require('../../../asset/tutorial/tutorial4.png')}
                alt="tutorial"
                width={600}
            />
            <p className="notification-tutorial__paragraph">5 All done, click the send button.</p>
        </div>
    );
};

export default NotificationTutorial;
