/**
 * Created by Di Wang on 2019-04-26.
 */
import React from 'react';
import { Modal, Radio } from 'antd';
import PropTypes from 'prop-types';
import SponsorCreationForm from './SponsorCreationForm';
import SponsorSelectionForm from './SponsorSelectionForm';

const { Group } = Radio;

class ExhibitorModal extends React.PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        isAddMode: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        initialData: PropTypes.object,
        allCompanies: PropTypes.array
    };

    state = {
        isSelectAction: true
    };

    _onSubmit = values => {
        const { onSubmit, initialData } = this.props;
        onSubmit &&
            onSubmit(
                {
                    ...values,
                    sponsorId: initialData.sponsorId
                },
                this.state.isSelectAction
            );
    };

    /**
     * Make sure the model shows the edit panel
     * @param props
     * @param state
     * @returns {null|{isSelectAction: boolean}}
     */
    static getDerivedStateFromProps(props, state) {
        if (!props.isAddMode && state.isSelectAction) {
            return {
                isSelectAction: false
            };
        }

        return null;
    }

    render() {
        const {
            isAddMode,
            visible,
            onCancel,
            isSubmitting,
            allCompanies,
            initialData
        } = this.props;
        const { isSelectAction } = this.state;
        return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
                title={isAddMode ? 'Add Sponsor' : 'Editor Sponsor'}
                visible={visible}
                onCancel={onCancel}>
                <div className="form-container">
                    {isAddMode && (
                        <Group
                            style={{ marginBottom: '20px' }}
                            value={isSelectAction}
                            onChange={e =>
                                this.setState({
                                    isSelectAction: e.target.value
                                })
                            }>
                            <Radio value={true}>Select a sponsor</Radio>
                            <Radio value={false}>Create a sponsor</Radio>
                        </Group>
                    )}
                    {isSelectAction ? (
                        <SponsorSelectionForm
                            isSubmitting={isSubmitting}
                            allCompanies={allCompanies}
                            isAddMode={isAddMode}
                            onSubmit={this._onSubmit}
                        />
                    ) : (
                        <SponsorCreationForm
                            isSubmitting={isSubmitting}
                            initialData={initialData}
                            isAddMode={isAddMode}
                            onSubmit={this._onSubmit}
                        />
                    )}
                </div>
            </Modal>
        );
    }
}

export default ExhibitorModal;
