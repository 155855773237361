/**
 * Created by Di Wang on 2019-01-08.
 */
import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

const { Item, create } = Form;

@create()
class TopicModal extends React.PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        onSubmit: PropTypes.func,
        isAddMode: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        initialData: PropTypes.object
    };

    render() {
        const { form, visible, onCancel, isSubmitting, isAddMode, initialData } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                footer={null}
                onCancel={onCancel}
                title={isAddMode ? 'Add Topic' : 'Edit Topic'}
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}>
                <Form onSubmit={this._formOnSubmit} style={{ padding: '0 20px 10px' }}>
                    <Item>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Topic name is required.' }],
                            initialValue: 'name' in initialData ? initialData.name : ''
                        })(<Input placeholder="Topic name" />)}
                    </Item>
                    <Button
                        loading={isSubmitting}
                        block
                        size="large"
                        type="primary"
                        htmlType="submit">
                        {isAddMode ? 'ADD' : 'UPDATE'}
                    </Button>
                </Form>
            </Modal>
        );
    }

    _formOnSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { onSubmit, isAddMode, initialData } = this.props;
                onSubmit && onSubmit({ ...values, id: isAddMode ? '' : initialData.id });
            }
        });
    };
}

export default TopicModal;
