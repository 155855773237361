/**
 * Created by Di Wang on 2019-05-02.
 */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import AvatarSelector from '../../../../component/AvatarSelector';
import { Button, Form, Input, Alert } from 'antd';

const { TextArea } = Input;
const { Item, create } = Form;

const ExhibitorCreationForm = props => {
  const { form, isAddMode, onSubmit, initialData, isSubmitting } = props;
  const { getFieldDecorator } = form;
  const [logo, setLogo] = useState('logo' in initialData ? initialData.logo : null);

  const _onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit({ ...values, logo, id: isAddMode ? '' : initialData.id });
      }
    });
  };

  return (
    <Form onSubmit={_onSubmit}>
      {!isAddMode && (
        <Alert
          style={{ marginBottom: 15 }}
          message="Modifying company info could affect all events."
          type="warning"
          showIcon
        />
      )}
      <AvatarSelector
        defaultAvatar={'logo' in initialData ? initialData.logo : null}
        folderName="companies"
        company
        isSquare={false}
        onChange={url => setLogo(url)}
      />
      <Item>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Company name is required.' }],
          initialValue: 'name' in initialData ? initialData.name : ''
        })(<Input placeholder="Company name" />)}
      </Item>
      <Item>
        {getFieldDecorator('description', {
          initialValue: 'description' in initialData ? initialData.description : ''
        })(<TextArea rows={3} placeholder="Company description..." />)}
      </Item>
      <Item>
        {getFieldDecorator('website', {
          initialValue: 'website' in initialData ? initialData.website : ''
        })(<Input placeholder="Website" />)}
      </Item>
      <Item>
        {getFieldDecorator('facebook', {
          initialValue: 'facebook' in initialData ? initialData.facebook : ''
        })(<Input placeholder="Facebook" />)}
      </Item>
      <Item>
        {getFieldDecorator('twitter', {
          initialValue: 'twitter' in initialData ? initialData.twitter : ''
        })(<Input placeholder="Twitter" />)}
      </Item>
      <Item>
        {getFieldDecorator('instagram', {
          initialValue: 'instagram' in initialData ? initialData.instagram : ''
        })(<Input placeholder="Instagram" />)}
      </Item>
      <Item>
        {getFieldDecorator('linkedin', {
          initialValue: 'linkedin' in initialData ? initialData.linkedin : ''
        })(<Input placeholder="Linkedin" />)}
      </Item>
      <Button loading={isSubmitting} size="large" block type="primary" htmlType="submit">
        {isAddMode ? 'ADD' : 'UPDATE'}
      </Button>
    </Form>
  );
};

ExhibitorCreationForm.propTypes = {
  isAddMode: PropTypes.bool,
  onSubmit: PropTypes.func,
  initialData: PropTypes.object,
  isSubmitting: PropTypes.bool
};

export default create()(ExhibitorCreationForm);
