/**
 * Created by Di Wang on 2018-12-06.
 */
import React, { PureComponent } from 'react';
import SideMenu from '../../component/SideMenu';
import MENU from './router';
import './event-detail.scss';
import { browserHistory } from 'react-router';

export default class EventDetails extends PureComponent {
    constructor(props) {
        super(props);
        const { state } = props.location;
        //Don't allow to visit if the state in location doesn't exist
        this.allowVisite = true;
        if (!state || !state.event) {
            this.allowVisite = false;
            browserHistory.replace('/404');
        }
    }

    render() {
        if (this.allowVisite) {
            return (
                <div className="event-detail">
                    <div className="event-detail__menu">
                        <SideMenu
                            menus={MENU}
                            prefix="/event/detail"
                            location={this.props.location}
                        />
                    </div>
                    <div className="event-detail__main">{this.props.children}</div>
                </div>
            );
        }

        return null;
    }
}
