/**
 * Created by Di Wang on 2018-12-17.
 */
import React from 'react';
import { Modal, Input, Icon, message, Button } from 'antd';
import PropTypes from 'prop-types';
import './file-modal.scss';
import UploadIndicator from '../../../../component/UploadIndicator';

/**
 * Display a file uploader modal
 */
export default class FileModal extends React.PureComponent {
    static propTypes = {
        /** allow to upload pdf into a certain event folder */
        eventFolderName: PropTypes.string.isRequired,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
        isAddMode: PropTypes.bool,

        defaultValue: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            url: PropTypes.string,
            key: PropTypes.string
        })
    };

    static defaultProps = {
        onSubmit: () => {},
        onCancel: () => {}
    };

    state = {
        defaultName: '',
        indicatorStatus: null,
        isShowErrorLabel: false,
        fileName: '',
        fileKey: '',
        fileUrl: ''
    };

    static getDerivedStateFromProps(nextProps, preState) {
        if (nextProps.defaultValue && nextProps.defaultValue.name !== preState.defaultName) {
            const { name, key, url } = nextProps.defaultValue;
            return {
                defaultName: name,
                fileName: name,
                fileKey: key,
                fileUrl: url
            };
        } else if (!nextProps.defaultValue && !!preState.defaultName) {
            return {
                defaultName: '',
                fileName: '',
                fileKey: '',
                fileUrl: ''
            };
        }

        return null;
    }

    render() {
        const { visible, isAddMode, onCancel, isSubmitting } = this.props;
        const { indicatorStatus, isShowErrorLabel, fileName, fileKey } = this.state;
        return (
            <Modal
                destroyOnClose={true}
                title={`${isAddMode ? 'Add' : 'Update'} File`}
                onCancel={onCancel}
                visible={visible}
                maskClosable={false}
                footer={null}
                className="file-modal">
                <div className="file-modal__container">
                    <Input
                        placeholder="Slide name or topic"
                        value={fileName}
                        onChange={e => {
                            this.setState({ fileName: e.target.value });
                        }}
                    />
                    <div className="file-modal__uploader-wrapper">
                        <input
                            type="file"
                            name="file"
                            accept="application/pdf"
                            className="file-modal__upload"
                            onChange={this._fileOnChange}
                        />
                        <div className="file-modal__upload-btn">
                            <Icon type="upload" /> Click to Upload
                        </div>
                        <UploadIndicator
                            className="file-modal__indicator"
                            status={indicatorStatus}
                        />
                    </div>
                    {fileKey && (
                        <div className="file-modal__attachment">
                            <span className="file-modal__filename">
                                <Icon type="paper-clip" />
                                {fileKey}
                            </span>
                        </div>
                    )}
                    {isShowErrorLabel && (
                        <p className="file-modal__error-label">Fill in the form</p>
                    )}

                    <Button
                        className="file-modal__btn"
                        loading={isSubmitting}
                        block
                        type="primary"
                        onClick={this._submitSlide}>
                        {isAddMode ? 'ADD' : 'UPDATE'}
                    </Button>
                </div>
            </Modal>
        );
    }

    _fileOnChange = async evt => {
        const file = evt.target.files[0];
        const { eventFolderName } = this.props;

        if (file) {
            this.setState({ indicatorStatus: UploadIndicator.STATUS.LOADING });
            // payload form data
            const data = new FormData();
            data.append('file', file);
            data.append('folder', `${eventFolderName}`);

            try {
                const res = await window.axios.post('/api/file/upload', data);
                this.setState({
                    indicatorStatus: UploadIndicator.STATUS.DONE,
                    fileKey: res.data.fileKey,
                    fileUrl: res.data.url
                });
            } catch (e) {
                message.error('Upload file failed');
                this.setState({ indicatorStatus: UploadIndicator.STATUS.FAILED });
            }
        }
    };

    /**
     * Pass form info to parent modal
     * @private
     */
    _submitSlide = async () => {
        const { fileKey, fileName, fileUrl } = this.state;

        if (fileName.trim().length <= 0 || fileUrl.trim().length <= 0) {
            this.setState({ isShowErrorLabel: true });
            setTimeout(() => {
                this.setState({ isShowErrorLabel: false });
            }, 3000);
            return;
        }

        const { isAddMode, defaultValue } = this.props;
        const payload = isAddMode
            ? { id: '', key: fileKey, name: fileName, url: fileUrl }
            : { id: defaultValue.id, key: fileKey, name: fileName, url: fileUrl };
        this.props.onSubmit(payload);
    };
}
