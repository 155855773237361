import React, { PureComponent } from 'react';
import './App.scss';
import Header from './Header';
import { BackTop } from 'antd';
import { browserHistory } from 'react-router';

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    const tokenInfo = localStorage.getItem('token');
    if (tokenInfo) {
      try {
        this.user = JSON.parse(tokenInfo).user;
      } catch (e) {
        this.user = null;
      }
      this.isAllowVisit = true;
    } else {
      this.isAllowVisit = false;
      browserHistory.replace('/login');
    }
  }

  render() {
    if (this.isAllowVisit) {
      return (
        <div className="App">
          <Header user={this.user} />
          <div className="App__container">{this.props.children}</div>
          <BackTop style={{ right: '30px', bottom: '30px' }} />
        </div>
      );
    } else {
      return null;
    }
  }
}
