/**
 * Created by Di Wang on 2019-07-13.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button } from 'antd';

const { Item, create } = Form;

@create()
class AttendeeModal extends React.PureComponent {
    static propTypes = {
        isAddMode: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        defaultValue: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            title: PropTypes.string,
            company: PropTypes.string
        }),
        isSubmitting: PropTypes.bool,
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        onSubmit: () => {}
    };

    _submitForm = e => {
        e.preventDefault();
        const { form, isAddMode, defaultValue } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                const payload = isAddMode
                    ? { ...values, id: '' }
                    : { ...values, id: defaultValue.id };
                this.props.onSubmit(payload);
            }
        });
    };

    render() {
        const { visible, onCancel, defaultValue, isSubmitting, isAddMode } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                footer={null}
                title={isAddMode ? 'Add Attendee' : 'Edit Attendee'}
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                onCancel={onCancel}>
                <Form onSubmit={this._submitForm} style={{ padding: '5px 50px 15px' }}>
                    <Item>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Name is required' }],
                            initialValue: defaultValue ? defaultValue.name : ''
                        })(<Input placeholder="Name" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Email is required' }],
                            initialValue: defaultValue ? defaultValue.email : ''
                        })(<Input placeholder="Email" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('title', {
                            initialValue: defaultValue ? defaultValue.title : ''
                        })(<Input placeholder="Title" />)}
                    </Item>
                    <Item>
                        {getFieldDecorator('company', {
                            initialValue: defaultValue ? defaultValue.company : ''
                        })(<Input placeholder="Company" />)}
                    </Item>
                    <Button htmlType="submit" block type="primary" loading={isSubmitting}>
                        {isAddMode ? 'ADD' : 'UPDATE'}
                    </Button>
                </Form>
            </Modal>
        );
    }
}

export default AttendeeModal;
