/**
 * @author Di Wang
 */
import React from 'react';
import '../../../constant/wonderland';
import { message, Button } from 'antd';
import './dashboard.scss';
import PieChart from '../../../component/PieChart';
import BarChart from '../../../component/BarChart';

export default class Dashboard extends React.PureComponent {
  state = {
    isShow: false,
    isFetching: true,
    joinedData: [],
    loginTypeData: [],
    savedSessionData: [],
    noteData: [],
    audioData: [],
    slideData: []
  };

  _getData = async () => {
    const query = `
      {
        event(id: "${this.props.location.state.event.id}") {
          attendees {
            joined
            user {
              loginType
              sessions {
                id
                name
              }
              notes {
                session {
                  id
                  name
                }
              }
            }
          }
          sessions {
            name
            audio {
              visited
            }
            slide {
              visited
            }
          }
        }
      }
    `;

    try {
      const res = await window.axios.post('/gql', { query }, { timeout: 0 });
      return res.data;
    } catch (e) {
      console.log(e);
      message.error('Get data unsuccessfully');
      this.setState({ isFetching: false });
      return null;
    }
  };

  _handleJoinedAttendees = attendees => {
    const total = attendees.length;
    const joinedCount = attendees.filter(attendee => attendee.joined).length;
    return [
      {
        name: 'Joined',
        value: joinedCount
      },
      {
        name: 'Non-joined',
        value: total - joinedCount
      }
    ];
  };

  _handleLoginType = attendees => {
    const joinedAttendees = attendees.filter(attendee => !!attendee.user);
    const localAuthCount = joinedAttendees.filter(attendee => attendee.user.loginType === 'LOCAL')
      .length;
    return [
      {
        name: 'Password-login',
        value: localAuthCount
      },
      {
        name: 'Social-login',
        value: joinedAttendees.length - localAuthCount
      }
    ];
  };

  _handleSavedSession = attendees => {
    const hasSessionAttendees = attendees.filter(
      attendee => attendee.user && attendee.user.sessions.length > 0
    );
    const session = {};
    const sessionName = {};
    hasSessionAttendees.forEach(attendee => {
      const { sessions } = attendee.user;
      sessions.forEach(item => {
        const { id } = item;
        if (id in session) {
          session[id] = session[id] + 1;
        } else {
          session[id] = 1;
        }

        if (!(id in sessionName)) {
          sessionName[id] = item.name;
        }
      });
    });
    return Object.keys(session)
      .map(key => ({
        name: sessionName[key],
        value: session[key]
      }))
      .sort((a, b) => a.value - b.value);
  };

  _handleNoteData = attendees => {
    const hasNoteAttendees = attendees.filter(
      attendee => attendee.user && attendee.user.notes.length > 0
    );
    const session = {};
    const noteName = {};
    hasNoteAttendees.forEach(attendee => {
      const { notes } = attendee.user;
      notes.forEach(note => {
        const { id, name } = note.session;
        if (id in session) {
          session[id] = session[id] + 1;
        } else {
          session[id] = 1;
        }

        if (!(id in noteName)) {
          noteName[id] = name;
        }
      });
    });
    return Object.keys(session)
      .map(key => ({
        name: noteName[key],
        value: session[key]
      }))
      .sort((a, b) => a.value - b.value);
  };

  _handleAudioData = sessions => {
    const hasAudioSession = sessions.filter(session => session.audio && session.audio.visited > 0);
    return hasAudioSession.map(session => ({
      name: session.name,
      value: session.audio.visited
    }));
  };

  _handleSlideData = sessions => {
    const hasSlideSession = sessions.filter(session => session.slide && session.slide.visited > 0);
    return hasSlideSession.map(session => ({
      name: session.name,
      value: session.slide.visited
    }));
  };

  _initChartData = async () => {
    this.setState({ isShow: true });
    const data = await this._getData();
    if (!data) {
      return;
    }
    const { attendees, sessions } = data.data.event;
    const joinedData = this._handleJoinedAttendees(attendees);
    const loginTypeData = this._handleLoginType(attendees);
    const savedSessionData = this._handleSavedSession(attendees);
    const noteData = this._handleNoteData(attendees);
    const audioData = this._handleAudioData(sessions);
    const slideData = this._handleSlideData(sessions);
    this.setState({
      joinedData,
      loginTypeData,
      savedSessionData,
      noteData,
      audioData,
      slideData,
      isFetching: false
    });
  };

  render() {
    const {
      isShow,
      isFetching,
      joinedData,
      loginTypeData,
      savedSessionData,
      noteData,
      audioData,
      slideData
    } = this.state;
    if (isShow) {
      return (
        <div className="dashboard">
          <div className="dashboard__row">
            <PieChart
              id="joined-attendees"
              title="Joined attendees"
              loading={isFetching}
              data={joinedData}
            />
            <PieChart
              id="login-type"
              title="Login type"
              loading={isFetching}
              data={loginTypeData}
            />
          </div>
          <BarChart
            id="write-note"
            title="Written note"
            loading={isFetching}
            data={noteData}
            height={400}
          />
          <BarChart
            id="saved-session"
            title="Saved session"
            loading={isFetching}
            data={savedSessionData}
          />
          <BarChart id="slide-count" title="Slides Clicked" loading={isFetching} data={slideData} />
          <BarChart id="audio-count" title="Audio Clicked" loading={isFetching} data={audioData} />
        </div>
      );
    } else {
      return (
        <div>
          <p>Clicking the button to get the stats.</p>
          <Button type="primary" onClick={this._initChartData}>
            Show Statistics
          </Button>
        </div>
      );
    }
  }
}
