/**
 * Created by Di Wang on 2019-07-13.
 */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import SubmitResult from '../SubmitResult';

class BulkImportModal extends React.PureComponent {
    static propTypes = {
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func
    };

    state = {
        isSubmitting: false,
        resultState: 'loading',
        loadingLabel: 'Parsing...'
    };

    _fileOnChange = e => {
        this.setState({ isSubmitting: true });
        Papa.parse(e.target.files[0], {
            header: true,
            complete: results => {
                this.setState({ loadingLabel: 'Checking...' });
                console.log(results);
                const { data, errors } = results;
                if (errors.length > 0) {
                    this.setState({ resultState: 'error' });
                } else {
                    this.setState({ loadingLabel: 'Submitting...' });
                    this.props.onSubmit && this.props.onSubmit(data);
                }
            }
        });
    };

    render() {
        const { visible, onCancel } = this.props;
        const { isSubmitting, resultState, loadingLabel } = this.state;
        return (
            <Modal
                footer={null}
                destroyOnClose={true}
                maskClosable={false}
                title="Bulk Import Attendees"
                onCancel={onCancel}
                visible={visible}>
                {isSubmitting ? (
                    <SubmitResult status={resultState} loadingLabel={loadingLabel} />
                ) : (
                    <>
                        <a
                            href={`${process.env.PUBLIC_URL}/attendee_template.csv`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'block', marginBottom: 20 }}>
                            Download template
                        </a>
                        <input
                            type="file"
                            onChange={this._fileOnChange}
                            name="file"
                            accept=".csv"
                        />
                    </>
                )}
            </Modal>
        );
    }
}

export default BulkImportModal;
