/**
 * Created by wangdi on 23/8/18.
 */
import React from 'react';
import './loader.scss';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

const Loader = ({ size, topRightCorner }) => {
    const className = ClassNames('loader', { 'loader__top-right': topRightCorner });
    return <div className={className} style={{ width: `${size}px`, height: `${size}px` }} />;
};

Loader.propTypes = {
    topRightCorner: PropTypes.bool,
    size: PropTypes.number
};

Loader.defaultProps = {
    size: 24
};

export default Loader;
