/**
 * Created by Di Wang on 2019-04-26.
 */
import React from 'react';
import { Radio, Modal } from 'antd';
import PropTypes from 'prop-types';
import ExhibitorCreationForm from './ExhibitorCreationForm';
import ExhibitorSelectionForm from './ExhibitorSelectionForm';

const { Group } = Radio;

export default class ExhibitorModal extends React.PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        isAddMode: PropTypes.bool,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        initialData: PropTypes.object,
        allCompanies: PropTypes.array
    };

    state = {
        isSelectAction: true
    };

    _onSubmit = values => {
        const { onSubmit } = this.props;
        onSubmit && onSubmit(values, this.state.isSelectAction);
    };

    /**
     * Make sure the model shows the edit panel
     * @param props
     * @param state
     * @returns {null|{isSelectAction: boolean}}
     */
    static getDerivedStateFromProps(props, state) {
        if (!props.isAddMode && state.isSelectAction) {
            return {
                isSelectAction: false
            };
        }

        return null;
    }

    render() {
        const {
            isAddMode,
            visible,
            onCancel,
            initialData,
            allCompanies,
            isSubmitting
        } = this.props;
        const { isSelectAction } = this.state;
        return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
                title={isAddMode ? 'Add Exhibitor' : 'Editor Exhibitor'}
                visible={visible}
                onCancel={onCancel}>
                <div className="form-container">
                    {isAddMode && (
                        <Group
                            style={{ marginBottom: '20px' }}
                            value={isSelectAction}
                            onChange={e =>
                                this.setState({
                                    isSelectAction: e.target.value
                                })
                            }>
                            <Radio value={true}>Select a exhibitor</Radio>
                            <Radio value={false}>Create a exhibitor</Radio>
                        </Group>
                    )}
                    {isSelectAction ? (
                        <ExhibitorSelectionForm
                            isSubmitting={isSubmitting}
                            allCompanies={allCompanies}
                            isAddMode={isAddMode}
                            onSubmit={this._onSubmit}
                        />
                    ) : (
                        <ExhibitorCreationForm
                            isSubmitting={isSubmitting}
                            initialData={initialData}
                            isAddMode={isAddMode}
                            onSubmit={this._onSubmit}
                        />
                    )}
                </div>
            </Modal>
        );
    }
}
