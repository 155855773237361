/**
 * Created by Di Wang on 2019-02-26.
 */
import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

const { Item, create } = Form;

@create()
class DeleteEventModal extends React.PureComponent {
    static propTypes = {
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool
    };

    static defaultProps = {
        onCancel: () => {},
        onSubmit: () => {}
    };

    render() {
        const { visible, onCancel, form, isSubmitting } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                title="Delete event?"
                footer={null}
                visible={visible}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={onCancel}>
                <Form onSubmit={this._onSubmit} style={{ padding: '10px 50px' }}>
                    <Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Password is required.' }]
                        })(
                            <Input
                                type="password"
                                placeholder="Enter password to verify your identity"
                            />
                        )}
                    </Item>
                    <Button
                        size="large"
                        htmlType="submit"
                        loading={isSubmitting}
                        type="primary"
                        block>
                        Delete
                    </Button>
                </Form>
            </Modal>
        );
    }

    _onSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };
}

export default DeleteEventModal;
