/**
 * Created by Di Wang on 2018-12-07.
 */
import React from 'react';
import UserTable from './UserTable';
import UserModal from './UserModal';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class User extends React.PureComponent {
    state = {
        users: [],
        userCount: 0,
        isFetching: false,

        userModalVisible: false
    };

    componentDidMount() {
        this._fetchUserData({ first: 10, skip: 0, isCount: true });
    }

    render() {
        const { userCount, users, isFetching, userModalVisible } = this.state;
        return (
            <div className="user">
                <Tabs defaultActiveKey="1" animated={false}>
                    <TabPane tab="Users" key="1">
                        <UserTable
                            loading={isFetching}
                            data={users}
                            userCount={userCount}
                            paginationOnChange={(page, pageSize) => {
                                this._fetchUserData({ first: pageSize, skip: page * pageSize });
                            }}
                            itemOnClick={this._tableItemOnClick}
                        />
                    </TabPane>
                    <TabPane tab="Presenters" key="2" />
                </Tabs>

                <UserModal
                    onCancel={() => {
                        this.setState({ userModalVisible: false });
                    }}
                    visible={userModalVisible}
                />
            </div>
        );
    }

    _tableItemOnClick = () => {
        this.setState({ userModalVisible: true });
    };

    /**
     * Get user data
     * @param first
     * @param skip
     * @param isCount
     * @returns {Promise<void>}
     * @private
     */
    _fetchUserData = async ({ first, skip, isCount = false }) => {
        this.setState({ isFetching: true });
        const query = `
			{
			  pagingUsers(first: ${first}, skip: ${skip}, isCount: ${isCount}) {
			    users {
			      id
			      name
			      email
			      avatar
			      title
			      company
			      city
			      country
			      bio
			      linkedin
			      twitter
			      facebook
			      github
			      phone
			      mobile
			      skype
			      activated
			    }
			    count
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            const { users, count } = res.data.data.pagingUsers;
            const data = users.map(user => Object.assign({}, user, { key: user.id }));
            if (isCount) {
                this.setState({
                    users: data,
                    userCount: count,
                    isFetching: false
                });
            } else {
                this.setState({ users, isFetching: false });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    };
}
