/**
 * @author Di Wang
 */
import React from 'react';
import { Modal, Input, Button, Form } from 'antd';
import PropTypes from 'prop-types';

const { create, Item } = Form;

@create()
class AudioModal extends React.PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isAddMode: PropTypes.bool,

    defaultValue: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  };

  static defaultProps = {
    onSubmit: () => {},
    onCancel: () => {}
  };

  _onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { name, url } = values;
        const { isAddMode, defaultValue } = this.props;
        const payload = isAddMode ? { id: '', name, url } : { id: defaultValue.id, name, url };
        this.props.onSubmit(payload);
      }
    });
  };

  render() {
    const { form, visible, isAddMode, onCancel, isSubmitting, defaultValue } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        destroyOnClose={true}
        title={`${isAddMode ? 'Add' : 'Update'} Audio`}
        onCancel={onCancel}
        visible={visible}
        maskClosable={false}
        footer={null}>
        <div style={{ padding: '0 20px 20px' }}>
          <Form onSubmit={this._onSubmit}>
            <Item>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name is required' }],
                initialValue: defaultValue ? defaultValue.name : 'Listen to session audio'
              })(<Input placeholder="Audio Name" />)}
            </Item>
            <Item>
              {getFieldDecorator('url', {
                rules: [{ required: true, message: 'Url is required' }],
                initialValue: defaultValue ? defaultValue.url : ''
              })(<Input placeholder="Audio Link" />)}
            </Item>
            <Button
              htmlType="submit"
              loading={isSubmitting}
              size="large"
              block
              type="primary"
              onClick={this._submitSlide}>
              {isAddMode ? 'ADD' : 'UPDATE'}
            </Button>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default AudioModal;
