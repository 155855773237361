import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router, Route, IndexRoute, browserHistory, IndexRedirect } from 'react-router';
import { message, Icon } from 'antd';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import App from './container/App';
import PureHtml from './container/PureHtml';
import Login from './container/Login';
// import Dashboard from './container/Dashboard';
import Event from './container/Event';
import Track from './container/Track';
import User from './container/User';
import Organisation from './container/Organisation';
import Notification from './container/Notification';
import NotFound from './container/NotFound';
import NoAuth from './container/NoAuthorisation';
import EventDetail from './container/EventDetail';
import EventDetailMenus from './container/EventDetail/router';

import axios from './axios';

window.axios = axios;
message.config({
  top: 40
});
Icon.setTwoToneColor('#28a745');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={browserHistory} onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/login" component={Login} />
        <Route path="/" component={App}>
          <IndexRoute component={Event} />
          <Route path="event" component={Event} />
          <Route path="user" component={User} />
          <Route path="organisation" component={Organisation} />
          <Route path="track" component={Track} />
          <Route path="notification" component={Notification} />
          <Route path="event/detail" component={EventDetail}>
            {EventDetailMenus.map(menu => (
              <Route key={menu.name} path={menu.route} component={menu.component} />
            ))}
            <IndexRedirect to="basic" />
          </Route>
        </Route>
        <Route path="/html" component={PureHtml} />
        <Route path="/404" component={NotFound} />
        <Route path="/no-auth" component={NoAuth} />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
