/**
 * Created by Di Wang on 2019-05-02.
 */
import React from 'react';
import { Button, Form, Select } from 'antd';
import * as PropTypes from 'prop-types';

const { Item, create } = Form;
const { Option } = Select;

const SponsorSelectionForm = props => {
  const { form, allCompanies, isAddMode, isSubmitting, onSubmit } = props;
  const { getFieldDecorator } = form;

  const _onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit(values);
      }
    });
  };

  return (
    <Form onSubmit={_onSubmit}>
      <Item>
        {getFieldDecorator('companyId', {
          rules: [{ required: true, message: 'Sponsor is required' }]
        })(
          <Select placeholder="Select a sponsor">
            {allCompanies.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        )}
      </Item>
      <Item>
        {getFieldDecorator('level', {
          rules: [{ required: true, message: 'Sponsorship level is required.' }]
        })(
          <Select placeholder="Sponsor level">
            <Option value="Platinum">Platinum</Option>
            <Option value="Gold">Gold</Option>
            <Option value="Silver">Silver</Option>
            <Option value="Bronze">Bronze</Option>
          </Select>
        )}
      </Item>
      <Button loading={isSubmitting} size="large" block type="primary" htmlType="submit">
        {isAddMode ? 'ADD' : 'UPDATE'}
      </Button>
    </Form>
  );
};

SponsorSelectionForm.propTypes = {
  isAddMode: PropTypes.bool,
  onSubmit: PropTypes.func,
  allCompanies: PropTypes.array,
  isSubmitting: PropTypes.bool
};

export default create()(SponsorSelectionForm);
