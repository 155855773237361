/**
 * Created by Di Wang on 2019-01-11.
 */
import React from 'react';
import { Table, Pagination, Icon } from 'antd';
import PropTypes from 'prop-types';

export default class UserTable extends React.PureComponent {
    static propTypes = {
        loading: PropTypes.bool,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string
            })
        ),
        userCount: PropTypes.number,
        paginationOnChange: PropTypes.func,
        itemOnClick: PropTypes.func
    };

    static defaultProps = {
        paginationOnChange: null,
        itemOnClick: null
    };

    columns = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            align: 'center',
            render: val => (val ? val : '-')
        },
        {
            title: 'Company',
            dataIndex: 'company',
            align: 'center',
            render: val => (val ? val : '-')
        },
        {
            title: 'Activated',
            dataIndex: 'activated',
            align: 'center',
            render: val =>
                val ? (
                    <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                ) : (
                    <Icon type="close-circle" theme="twoTone" twoToneColor="#f30" />
                )
        }
    ];

    render() {
        const { data, loading, userCount, itemOnClick } = this.props;
        return (
            <Table
                className="track-table"
                loading={loading}
                dataSource={data}
                columns={this.columns}
                pagination={<Pagination total={userCount} onChange={this._paginationOnChange} />}
                onRow={(record, rowIndex) => ({
                    onClick: evt => {
                        itemOnClick && itemOnClick(rowIndex);
                    }
                })}
            />
        );
    }

    _paginationOnChange = (page, pageSize) => {
        const { paginationOnChange } = this.props;
        paginationOnChange && paginationOnChange(page, pageSize);
    };
}
