/**
 * Created by Di Wang on 2018-12-10.
 */
import React from 'react';
import TopicTable from './TopicTable';
import { Button, Icon, message } from 'antd';
import TopicModal from './TopicModal';

export default class DiscussionBoard extends React.PureComponent {
    state = {
        topics: [],
        isFetching: false,
        isSubmitting: false,
        modalVisible: false,
        isAddMode: true,
        initialData: {}
    };

    componentDidMount() {
        this._getTopicData();
    }

    _toggleModal = () => {
        this.setState({ modalVisible: !this.state.modalVisible });
    };

    /**
     * Get Topic data
     */
    _getTopicData = async () => {
        this.setState({ isFetching: true });
        const query = `
		  {
		    event(id: "${this.props.location.state.event.id}") {
			  topics {
			    id
			    name
			    pin
			    lock
			  }
			}
		  }
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.event) {
                this.setState({ topics: res.data.data.event.topics, isFetching: false });
            } else {
                message.error(res.data.data.errors[0].message);
                this.setState({ isFetching: false });
            }
        } catch (e) {
            console.log(e);
            message.error('Get topic data failed');
            this.setState({ isFetching: false });
        }
    };

    _deleteItem = async id => {
        const query = `
			mutation {
			  deleteTopic(id: "${id}") {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.deleteTopic) {
                message.success('Delete topic successful');
                this._getTopicData();
            } else {
                message.success(res.data.data.errors[0].message);
            }
        } catch (e) {
            message.success('Delete topic failed');
        }
    };

    _pinItem = async (item, checked) => {
        const query = `
			mutation {
			  updateTopic(id: "${item.id}", pin: ${checked}, name: "${item.name}") {
			    id
			  }
			}
		`;

        try {
            await window.axios.post('/gql', { query });
        } catch (e) {}
    };

    _lockItem = async (item, checked) => {
        const query = `
			mutation {
			  updateTopic(id: "${item.id}", lock: ${checked}, name: "${item.name}") {
			    id
			  }
			}
		`;

        try {
            await window.axios.post('/gql', { query });
        } catch (e) {}
    };

    _onSubmit = values => {
        if (this.state.isAddMode) {
            this._createTopic(values);
        } else {
            this._updateTopic(values);
        }
    };

    _createTopic = async values => {
        this.setState({ isSubmitting: true });
        const query = `
			mutation {
				createTopic(eventId: "${this.props.location.state.event.id}", name: "${values.name}") {
					id
				}
			}
		`;

        try {
            await window.axios.post('/gql', { query });
            message.success('Create topic successful');
            this.setState({ modalVisible: false });
            this._getTopicData();
        } catch (e) {
            message.error('Create topic failed');
        }

        this.setState({ isSubmitting: false });
    };

    _updateTopic = async values => {
        this.setState({ isSubmitting: true });
        const query = `
			mutation {
				updateTopic(id: "${values.id}", name: "${values.name}") {
					id
				}
			}
		`;

        try {
            await window.axios.post('/gql', { query });
            message.success('Update topic successful');
            this.setState({ modalVisible: false });
            this._getTopicData();
        } catch (e) {
            message.error('Update topic failed');
        }

        this.setState({ isSubmitting: false });
    };

    render() {
        const {
            topics,
            isFetching,
            modalVisible,
            isAddMode,
            isSubmitting,
            initialData
        } = this.state;
        return (
            <div className="discussion-board">
                <div className="toolbar">
                    <div />
                    <Button
                        type="primary"
                        onClick={() => {
                            this.setState({ modalVisible: true, isAddMode: true, initialData: {} });
                        }}>
                        <Icon type="plus" /> Add Topic
                    </Button>
                </div>
                <TopicTable
                    loading={isFetching}
                    data={topics}
                    onEdit={item => {
                        this.setState({
                            modalVisible: true,
                            isAddMode: false,
                            initialData: item
                        });
                    }}
                    onDelete={this._deleteItem}
                    onPinSwitch={this._pinItem}
                    onLockSwitch={this._lockItem}
                />
                <TopicModal
                    initialData={initialData}
                    isSubmitting={isSubmitting}
                    isAddMode={isAddMode}
                    onCancel={this._toggleModal}
                    visible={modalVisible}
                    onSubmit={this._onSubmit}
                />
            </div>
        );
    }
}
