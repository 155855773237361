/**
 * Created by Di Wang on 2018-12-05.
 */
import React, { PureComponent } from 'react';
import './login.scss';
import { Form, Input, Icon, Button, message } from 'antd';
import { browserHistory } from 'react-router';

const { create, Item } = Form;

@create()
class Login extends PureComponent {
    state = {
        loading: false
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login">
                <div className="login__footer" />
                <div className="login__card-container">
                    <div className="login__block-left">
                        <img
                            src={require('../../asset/logo/logo-vertical.png')}
                            alt="logo"
                            className="login__logo"
                        />
                        <h3 className="login__title">Administrator Portal</h3>
                    </div>
                    <div className="login__block-right">
                        <h2 className="login__title" style={{ color: 'rgb(81, 90, 110)' }}>
                            Welcome
                        </h2>
                        <Form className="login__form" onSubmit={this._formOnSubmit}>
                            <Item>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, messages: 'Email is required' }]
                                })(
                                    <Input
                                        prefix={<Icon type="mail" className="login__icon" />}
                                        placeholder="Email"
                                        size="large"
                                    />
                                )}
                            </Item>
                            <Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, messages: 'Password is required' }]
                                })(
                                    <Input
                                        prefix={<Icon type="lock" className="login__icon" />}
                                        placeholder="Password"
                                        type="password"
                                        size="large"
                                    />
                                )}
                            </Item>
                            <Button
                                loading={this.state.loading}
                                block
                                type="primary"
                                htmlType="submit"
                                size="large">
                                LOGIN
                            </Button>
                        </Form>
                    </div>
                </div>
                <p className="login__copyright">2019 &copy; Vumero. All rights reserved.</p>
            </div>
        );
    }

    /**
     * Admin login logic
     * @param e
     * @private
     */
    _formOnSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const query = `
					mutation {
					  loginAsAdmin(email: "${values.email}", password: "${values.password}") {
					    token
					    user {
					      firstName
					      lastName
					      avatar
					    }
					  }
					}
				`;
                try {
                    const res = await window.axios.post('/gql', { query });
                    if (res.data.errors) {
                        message.error(res.data.errors[0].message);
                        this.setState({ loading: false });
                    } else {
                        const data = res.data.data;
                        // console.log(data.loginAsAdmin);
                        //save token into local storage
                        localStorage.setItem('token', JSON.stringify(data.loginAsAdmin));
                        //navigate to home page
                        browserHistory.replace('/');
                    }
                } catch (e) {
                    message.error('Login failed');
                    this.setState({ loading: false });
                }
            }
        });
    };
}

export default Login;
