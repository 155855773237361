/**
 * Created by Di Wang on 2019-04-25.
 */
import React from 'react';
import './organisation.scss';
import { Button, Icon, message } from 'antd';
import CompanyTable from './CompanyTable';

export default class Organisation extends React.PureComponent {
    state = {
        isFetching: false,
        companies: []
    };

    _getCompanies = async () => {
        this.setState({ isFetching: true });
        const query = `
			{
			  companies {
			    id
			    name
			    website
			    instagram
			    facebook
			    twitter
			    linkedin
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.companies) {
                this.setState({ companies: res.data.data.companies });
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Get company list failed');
        }

        this.setState({ isFetching: false });
    };

    componentDidMount() {
        this._getCompanies();
    }

    _onEdit = () => {};

    _onDelete = () => {};

    render() {
        const { isFetching, companies } = this.state;
        return (
            <div className="organisation">
                <div className="toolbar">
                    <div />
                    <Button
                        type="primary"
                        onClick={() => {
                            message.info('Will be implemented soon');
                        }}>
                        <Icon type="plus" /> Create Company
                    </Button>
                </div>
                <CompanyTable loading={isFetching} data={companies} />
            </div>
        );
    }
}
