/**
 * Created by Di Wang on 2019-05-02.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Select } from 'antd';

const { Option } = Select;
const { Item, create } = Form;

const ExhibitorSelectionForm = props => {
    const { form, isAddMode, onSubmit, allCompanies, isSubmitting } = props;
    const { getFieldDecorator } = form;

    const _onSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit && onSubmit(values);
            }
        });
    };

    return (
        <Form onSubmit={_onSubmit}>
            <Item>
                {getFieldDecorator('companyId', {
                    rules: [{ required: true, message: 'Exhibitor is required' }]
                })(
                    <Select placeholder="Select a exhibitor">
                        {allCompanies.map(item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                )}
            </Item>
            <Button loading={isSubmitting} size="large" block type="primary" htmlType="submit">
                {isAddMode ? 'ADD' : 'UPDATE'}
            </Button>
        </Form>
    );
};

ExhibitorSelectionForm.propTypes = {
    isAddMode: PropTypes.bool,
    onSubmit: PropTypes.func,
    allCompanies: PropTypes.array,
    isSubmitting: PropTypes.bool
};

export default create()(ExhibitorSelectionForm);
