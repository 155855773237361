/**
 * Created by Di Wang on 2018-12-05.
 */
import axios from 'axios';
import { browserHistory } from 'react-router';

// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.timeout = 20000;

axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token');
    if (token) {
      try {
        const auth = JSON.parse(token);
        config.headers.Authorization = `Bearer ${auth.token}`;
      } catch (e) {}
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          browserHistory.replace('/no-auth');
          break;
        default:
          break;
      }
    }

    return Promise.reject(err);
  }
);

export default axios;
