/**
 * Created by Di Wang on 2019-01-11.
 */
import React from 'react';
import { Modal } from 'antd';
import './user-modal.scss';
import PropTypes from 'prop-types';

export default class UserModal extends React.PureComponent {
    static propTypes = {
        visible: PropTypes.bool,
        user: PropTypes.shape({
            id: PropTypes.string
        }),
        onCancel: PropTypes.func
    };

    render() {
        const { visible, onCancel } = this.props;
        return <Modal onCancel={onCancel} className="user-modal" visible={visible} />;
    }
}
