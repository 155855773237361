/**
 * Created by Di Wang on 2018-12-08.
 */
import React, { PureComponent } from 'react';
import RequiredTitle from '../../../component/RequiredTitle';
import './event-sponsor.scss';
import UploadIndicator from '../../../component/UploadIndicator';
import { message, Icon, Popconfirm, Button } from 'antd';
import Loader from '../../../component/Loader';
import SponsorTable from './SponsorTable';
import SponsorModal from './SponsorModal';

export default class EventSponsor extends PureComponent {
    state = {
        sponsorImg: null,
        sponsors: [],
        sponsorIndicatorStatus: null,
        isFetching: true,
        modalVisible: false,
        isAddMode: true,
        /** list for select exhibitors to add */
        allCompanies: [],
        isSubmitting: false,
        initialData: {}
    };

    componentDidMount() {
        this._getSponsors(true);
        this._getAllCompanies();
    }

    /**
     * Get sponsor data
     * @param includeCoverImage: true means getting the sponsor cover image as well
     */
    _getSponsors = async (includeCoverImage = false) => {
        this.setState({ isFetching: true });
        let query = '';
        if (includeCoverImage) {
            query = `
				{
				  event(id: "${this.props.location.state.event.id}") {
				    sponsorImg
				    sponsors {
                      id
                      level
                      company {
                        id
                        name
                        logo
                        description
                        website
                        facebook
                        twitter
                        instagram
                        linkedin
                      }
                    }
				  }
				}
			`;
        } else {
            query = `
				{
				  event(id: "${this.props.location.state.event.id}") {
				    sponsors {
                      id
                      level
                      company {
                        id
                        name
                        logo
                        description
                        website
                        facebook
                        twitter
                        instagram
                        linkedin
                      }
                    }
				  }
				}
			`;
        }

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.event) {
                if (includeCoverImage) {
                    const { sponsorImg, sponsors } = res.data.data.event;
                    this.setState({ sponsorImg, sponsors });
                } else {
                    const { sponsors } = res.data.data.event;
                    this.setState({ sponsors });
                }
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Get sponsor data failed');
        }

        this.setState({ isFetching: false });
    };

    /**
     * Upload sponsor cover image
     * @param evt
     * @private
     */
    _sponsorCoverImageUploadOnChange = async evt => {
        const file = evt.target.files[0];

        if (file) {
            this.setState({ sponsorIndicatorStatus: UploadIndicator.STATUS.LOADING });
            // payload form data
            const data = new FormData();
            data.append('file', file);
            data.append('folder', `${this.props.location.state.event.folderName}`);

            try {
                const res = await window.axios.post('/api/file/upload', data);
                await this._updateSponsorCoverImage(res.data.url);
            } catch (e) {
                this.setState({ sponsorIndicatorStatus: UploadIndicator.STATUS.FAILED });
            }
        }
    };

    /**
     * Update Sponsor cover image
     * @param imgUrl
     * @private
     */
    _updateSponsorCoverImage = async imgUrl => {
        const query = `
			mutation($imgUrl: String) {
			  updateEvent(id: "${this.props.location.state.event.id}", sponsorImg: $imgUrl) {
			    id
			  }
			}
		`;

        try {
            await window.axios.post('/gql', { query, variables: { imgUrl } });
            this.setState({
                sponsorImg: imgUrl,
                sponsorIndicatorStatus: UploadIndicator.STATUS.DONE
            });
            message.success('Update sponsor cover image successful');
        } catch (e) {
            message.error('Update sponsor cover image failed');
        }
    };

    _getAllCompanies = async () => {
        const query = `
	        {
			  companies {
			    id
			    name
			  }
			}
    	`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.companies) {
                this.setState({ allCompanies: res.data.data.companies });
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Get company list failed');
        }
    };

    _onSubmit = async (values, isByExist) => {
        if (this.state.isAddMode) {
            if (isByExist) {
                this._addSponsorByExisting(values);
            } else {
                this._addSponsorByNew(values);
            }
        } else {
            this._updateCompany(values);
        }
    };

    _updateCompany = async values => {
        this.setState({ isSubmitting: true });
        const query = `
			mutation(
			  $logo: String
			  $website: String
			  $description: String
			  $facebook: String
			  $twitter: String
			  $instagram: String
			  $linkedin: String
			) {
			  updateCompany(
			    id: "${values.id}"
			    name: "${values.name}"
			    logo: $logo
			    website: $website
			    description: $description
			    facebook: $facebook
			    twitter: $twitter
			    instagram: $instagram
			    linkedin: $linkedin
			  ) {
			    id
			  }
			  updateSponsor(id: "${values.sponsorId}", level: "${values.level}"){
                id
              }
			}
		`;

        const { logo, website, description, facebook, twitter, instagram, linkedin } = values;
        try {
            const res = await window.axios.post('/gql', {
                query,
                variables: { logo, website, description, facebook, twitter, instagram, linkedin }
            });
            if (res.data.data.updateCompany) {
                message.success('Update company info successful');
                this.setState({ modalVisible: false });
                this._getSponsors();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Update company info failed');
        }
        this.setState({ isSubmitting: false });
    };

    _addSponsorByNew = async values => {
        this.setState({ isSubmitting: true });
        const query = `
	        mutation(
			  $logo: String
			  $website: String
			  $description: String
			  $facebook: String
			  $twitter: String
			  $instagram: String
			  $linkedin: String
			) {
			  createSponsorByNew(
			    eventId: "${this.props.location.state.event.id}"
			    level: "${values.level}"
			    name: "${values.name}"
			    logo: $logo
			    website: $website
			    description: $description
			    facebook: $facebook
			    twitter: $twitter
			    instagram: $instagram
			    linkedin: $linkedin
			  ) {
			    id
			  }
			}
		`;

        const { logo, description, website, facebook, twitter, instagram, linkedin } = values;
        try {
            const res = await window.axios.post('/gql', {
                query,
                variables: {
                    logo,
                    description,
                    website,
                    facebook,
                    twitter,
                    instagram,
                    linkedin
                }
            });
            if (res.data.data.createSponsorByNew) {
                message.success('Add sponsor successful');
                this.setState({ modalVisible: false });
                this._getSponsors();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Add sponsor failed');
        }

        this.setState({ isSubmitting: false });
    };

    _addSponsorByExisting = async values => {
        this.setState({ isSubmitting: true });
        const query = `
    	    mutation {
			  createSponsorByExisting(
			    level: "${values.level}"
			    companyId: "${values.companyId}"
			    eventId: "${this.props.location.state.event.id}"
			  ) {
			    id
			  }
			}
    	`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.createSponsorByExisting) {
                message.success('Add sponsor successful');
                this.setState({ modalVisible: false });
                this._getSponsors();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Add sponsor failed');
        }

        this.setState({ isSubmitting: false });
    };

    /**
     * This is only to remove the connect between exhibitor and event rather than deleting exhibitor
     * @param item
     * @returns {Promise<void>}
     * @private
     */
    _deleteSponsor = async item => {
        const query = `
			mutation {
			  deleteSponsor(id: "${item.id}") {
			    id
			  }
			}
		`;

        try {
            const res = await window.axios.post('/gql', { query });
            if (res.data.data.deleteSponsor) {
                message.success('Delete sponsor successful');
                this._getSponsors();
            } else {
                message.error(res.data.errors[0].message);
            }
        } catch (e) {
            message.error('Delete sponsor failed');
        }
    };

    render() {
        const {
            sponsorIndicatorStatus,
            sponsorImg,
            sponsors,
            isFetching,
            modalVisible,
            isAddMode,
            isSubmitting,
            initialData,
            allCompanies
        } = this.state;
        return (
            <div className="event-sponsor">
                {isFetching && <Loader topRightCorner={true} size={20} />}
                <RequiredTitle>Sponsor Cover Image</RequiredTitle>
                <p className="event-basic__subtitle">Accepted file types: jpg/jpeg, png.</p>
                <div
                    className="event-sponsor__upload-wrapper"
                    style={{ borderStyle: sponsorImg ? 'solid' : 'dashed' }}>
                    {sponsorImg ? (
                        <img src={sponsorImg} className="event-basic__logo-img" alt="logo" />
                    ) : (
                        <p>+ Add Full Sponsor Image</p>
                    )}
                    <input
                        type="file"
                        name="file"
                        accept="image/png, image/jpeg"
                        className="event-sponsor__upload-input"
                        onChange={this._sponsorCoverImageUploadOnChange}
                    />
                    <UploadIndicator
                        status={sponsorIndicatorStatus}
                        className="event-sponsor__upload-indicator"
                    />
                    {sponsorImg && (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => {
                                this._updateSponsorCoverImage(null);
                            }}>
                            <Icon type="delete" className="event-sponsor__delete-btn" />
                        </Popconfirm>
                    )}
                </div>

                <div className="toolbar">
                    <RequiredTitle>Sponsor List</RequiredTitle>
                    <Button
                        type="primary"
                        onClick={() => {
                            this.setState({
                                isAddMode: true,
                                modalVisible: true,
                                initialData: {}
                            });
                        }}>
                        <Icon type="plus" /> Add Sponsor
                    </Button>
                </div>
                <SponsorTable
                    loading={isFetching}
                    data={sponsors}
                    onDelete={this._deleteSponsor}
                    onEdit={item => {
                        this.setState({
                            isAddMode: false,
                            modalVisible: true,
                            initialData: { ...item.company, level: item.level, sponsorId: item.id }
                        });
                    }}
                />
                <SponsorModal
                    isSubmitting={isSubmitting}
                    allCompanies={allCompanies}
                    initialData={initialData}
                    isAddMode={isAddMode}
                    onCancel={() => this.setState({ modalVisible: false })}
                    visible={modalVisible}
                    onSubmit={this._onSubmit}
                />
            </div>
        );
    }
}
