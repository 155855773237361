/**
 * Created by Di Wang on 2019-01-23.
 */
import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import AvatarSelector from '../../../../component/AvatarSelector';

const { Item, create } = Form;
const { TextArea } = Input;
const { Option } = Select;

@create()
class PresenterCreationForm extends React.PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
        isRequired: PropTypes.bool,
        types: PropTypes.array
    };

    static defaultProps = {
        onSubmit: () => {}
    };

    state = {
        avatar: null
    };

    render() {
        const { form, loading, isRequired, types } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this._onSubmit}>
                <AvatarSelector
                    folderName="presenters"
                    style={{ marginBottom: '15px' }}
                    onChange={val => this.setState({ avatar: val })}
                />
                <Item>
                    {getFieldDecorator('role', {
                        rules: [{ required: isRequired }]
                    })(
                        <Select placeholder="Select the role">
                            {types.map(role => (
                                <Option key={role} value={role}>
                                    {role}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Item>
                <Item>
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: isRequired
                            }
                        ]
                    })(<Input placeholder="Name" />)}
                </Item>
                <Item>{getFieldDecorator('title', {})(<Input placeholder="Title" />)}</Item>
                <Item>{getFieldDecorator('company', {})(<Input placeholder="Company" />)}</Item>
                <Item>{getFieldDecorator('bio', {})(<TextArea placeholder="Bio" rows={3} />)}</Item>
                <Item>{getFieldDecorator('linkedin', {})(<Input placeholder="Linkedin" />)}</Item>
                <Item>{getFieldDecorator('facebook', {})(<Input placeholder="Facebook" />)}</Item>
                <Item>{getFieldDecorator('twitter', {})(<Input placeholder="Twitter" />)}</Item>
                <Item>{getFieldDecorator('instagram', {})(<Input placeholder="Instagram" />)}</Item>
                <Button loading={loading} size="large" block type="primary" htmlType="submit">
                    SUBMIT
                </Button>
            </Form>
        );
    }

    _onSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    ...values,
                    avatar: this.state.avatar,
                    role: values.role.toUpperCase()
                });
            }
        });
    };
}

export default PresenterCreationForm;
